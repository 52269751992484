.pagination {
  //@include pagination-container; is not needed because the container already has the Foundation class name
  .active {
    @include pagination-item-current;
  }
  .disabled {
    //@include pagination-item-disabled; is not needed because the container already has the Foundation class name
  }
  .ellipsis::after {
    //@include pagination-ellipsis; Concrete5 hardcodes an elipsis in a disabled page
  }

  @if $pagination-arrows {
    .prev a::before,
    .prev.disabled::before {
      display: inline-block;
      margin-#{$global-right}: 0.5rem;
      content: '\00ab';
    }

    .next a::after,
    .next.disabled::after {
      display: inline-block;
      margin-#{$global-left}: 0.5rem;
      content: '\00bb';
    }
  }

}