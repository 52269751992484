// see
// https://css-tricks.com/styling-underlines-web/
// https://codepen.io/jimmynotjim/pen/EabQjV

h1, h2, h3, h4, h5, h6 {
  a {
    text-decoration-line: none; // cancel normal link decoration
    background-size: 0.05em 0.05em; // width of underline
    //background-size: 0.1em 0.1em; // width of underline
    background-repeat: repeat-x;
    background-position: 0% 91%; // position of underline: 0% top, 100% bottom
    background-image: linear-gradient(rgba($primary-color, 0.3), rgba($primary-color, 0.3));
    &:hover {
      text-decoration-line: none;
      background-image: linear-gradient($anchor-color-hover, $anchor-color-hover);
    }
  }
}

a {
  text-decoration-color: rgba($primary-color, 0.3);
  &:hover {
    text-decoration-color: $anchor-color-hover;
  }
}

a.text-decoration { // can be applied to links in headers to override header link behaviour
  text-decoration-line: underline !important;
  background-image: none !important;
}

@each $name, $color in map-merge($gg-palette, $gg-foundation-palette) {

  .color-#{$name} {
    a {
      text-decoration-color: rgba($color, 0.3);
      &:hover {
        $anchor-color-hover: scale-color($color, $lightness: -14%);
        color: $anchor-color-hover;
        text-decoration-color: $anchor-color-hover;
      }
    }
    h1, h2, h3, h4, h5, h6 {
      a {
        background-image: linear-gradient(rgba($color, 0.3), rgba($color, 0.3));
        &:hover {
          $anchor-color-hover: scale-color($color, $lightness: -14%);
          background-image: linear-gradient($anchor-color-hover, $anchor-color-hover);
        }
      }
    }
  }

  .background-#{$name} {
    // determine whether background is dark enough to invert color (i.e. make it white)
    $new-color: set-color-from-background($color);
    //@include color-set(set-color-from-background($color));

    h1, h2, h3, h4, h5, h6 {
      a {
        background-image: linear-gradient(rgba($new-color, 0.3), rgba($new-color, 0.3));
        &:hover {
          $anchor-color-hover: scale-color($new-color, $lightness: -14%);
          color: $anchor-color-hover;
          background-image: linear-gradient($anchor-color-hover, $anchor-color-hover);
        }
      }
    }
  }
}