@function set-color-from-background($color) {
  @if (lightness($color) < 60) { // threshold is lightness of less than 60%
    @return $white;
  } @else {
    @return $body-font-color;
  }
}

.background-teal { // used in strapline, footer
  background-color: $ggteal;
  color: $white;
  //@include color-set($white);
  a {
    color: $white;
    text-decoration-color: rgba($white, 0.5);
    &:hover {
      color: $white;
      text-decoration-color: $white;
    }
  }
}

@each $name, $color in map-merge($gg-palette, $gg-foundation-palette) {
  .color-#{$name} {
    color: $color;
    @include color-set($color);
    &.subheader {
      //color: darken($color, 20%);
      color: rgba($color, 0.3);
    }
  }
  .background-#{$name} {
    background-color: $color;
    // determine whether background is dark enough to invert color (i.e. make it white)
    color: set-color-from-background($color);
    @include color-set(set-color-from-background($color));
  }
  .bg-#{$name} { // basic background color change (no foreground color change)
    background-color: $color;
  }
}