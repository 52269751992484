.nav-bar {
  @include grid-row;
  @include grid-column;
  float: none; // override grid-column float: left which breaks this semantic combined row/column
}

.nav-bar { // same styling for both menus
  .menu { // the horizontal menu bar
    text-transform: uppercase;
    .submenu { // top level menu only is uppercase
      text-transform: none;
      //position: relative;
      a {
        border-color: transparent;
        //position: absolute;
        //&:before {
        //  content: '';
        //  height: 100%;
        //  transform: scaleX(1);
        //}
        &:hover {
          transition: border-color 0.5s ease;
          border-left: 5px $ggcorel solid;
        }
      }
    }
    a {
      text-decoration: none; // default $anchor-text-decoration
      &:hover { // link hover feedback
        //text-decoration: underline; // default $anchor-text-decoration-hover
        background-color: $dropdownmenu-background;
      }
      //padding: 0.3rem 0.7rem; // override $global-menu-padding to save space in .top-bar
    }
    .active > a { // override active link coloring and revert text color
      color: $anchor-color;
      background-color: transparent;
      &:hover {
        color: $anchor-color-hover;
      }
    }
  }
  &.v2 {
    .menu {
      .submenu {
        a {
          color: $black;
        }
      }
    }
  }
}
