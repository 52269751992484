.strapline {
  clear: both;
  @extend .background-teal;
  $strapline-padding: (rem-calc(45) - rem-calc($global-lineheight * $global-font-size))/2;
  padding-top: $strapline-padding;
  padding-bottom: $strapline-padding;
  &__text {
    @include grid-row;
    @include grid-column;
    float: none; // override grid-column float: left which breaks this semantic combined row/column
  }
}