.media-object {
  &.media-object--executive-team, &.media-object--keyline { // the executive-team modifier is applied to the media-objects on the executive-team page
    padding: 1rem;
    border: solid 1px $ggteal;
    //.media-object-section {
    //  flex: 0 1 auto; // reinstate default behaviour
    //}
  }
  &.media-object--tint {
    padding: 1rem;
    border: none; // reset
    background-color: $ggtint;
  }
}