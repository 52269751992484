$gg-tabs-background: $ggteal;

.tabs {
  &--gg { // gg modified tabs
    border: none;
    background: none;
    margin: 0 0.5rem;
    text-align:center; // see https://gist.github.com/corilam/5c935e6fb6bdef40b78b4c66e4b78dc9
    .tabs-title {
      float:none !important;
      display:inline-block;
      > a {
        padding: 0.75rem 1.5rem;
        margin-bottom: 0.5rem;
        font-weight: bold;
        color: $white;
        text-decoration: none;
        background-color: $gg-tabs-background;
        //border: solid 1px $gg-tabs-background;
        &:focus, &[aria-selected='true'], &[aria-selected='true']:hover {
          background: $ggtint;
          color: $ggteal;
        }
        &:hover {
          color: $white;
          background: scale-color($gg-tabs-background, $lightness: -15%);
        }
      }
      &:not(.column) { // when the tab isn't in a column, we need to add margin between them
        > a {
          margin: 0 0.5rem 0.5rem;
        }
      }
    }
  }
}

.tabs-content {
  &--gg {
    border: none;
    .tabs-panel {
      padding: 0;
    }
  }
}

.news-abstract {
  margin-bottom: 1rem;
}

.time-and-place {
  margin-bottom: 0;
}