.card {
  &--customer, &--keyline { // the card--customer modifier is applied to the cards on the customers overview page
    border: solid 1px $ggteal;
    .card-section {
      flex: 0 1 auto; // reinstate default behaviour
    }
  }

  &--tint, &--product {
    //height: 100%; // use data-equalizer to do this
    //margin-bottom: 0; // this might cause problems
    display: flex;
    flex-direction: column;
    .card-section {
      display: flex;
      flex-direction: column;

      &__top { // used to keep the top card-section from growing
        flex: 0 1 auto;
      }

      &__bottom {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        a.button {
          margin-bottom: 0;
        }
      }
    }
  }
  &--tint {
    border: none; // reset
    background-color: $ggtint;
  }

  &--product {
    //.card-section {
    //  flex: 0 1 auto; // reinstate default behaviour
    //}
    @each $class, $color in $gg-palette {
      &.#{$class} {
        background-color: tint($color, 10%);
        border: none; // reset
        border-top: rem-calc(24) solid $color;
        a.button {
          $button-background-hover: scale-color($color, $lightness: -15%); // from _settings.scss
          $button-color: $white;
          @include button-style($color, $button-background-hover, $button-color);
        }
      }
    }
  }

  &--dfe { // cards first used on SmartDFE pages with a view to aligned CTA buttons
    height: 100%;
    .card-section {
      padding-bottom: 0;
      &:last-child {
        padding-bottom: 1rem;
      }
      &:first-child, &:last-child {
        flex-grow: 0;
      }
      &__bottom .button {
        margin-bottom: 0;
      }
      &__top img {
        //height: 100px !important;
      }
    }
  }
}