.hero {
  text-align: center;
  &__header {
    font-weight: 400; // normal
    font-size: $hero-header-font-size;
    //margin-bottom: $paragraph-margin-bottom;
    //text-transform: uppercase;

    //p:last-child {
    //  margin-bottom: 0;
    //}
  }
  .with-dark-background & {
    color: $white;
  }


  &--homestyle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end; // fill from the bottom
    text-align: left;

    background-size: cover;

    min-height: 150px; // needs to be responsive
    @include breakpoint(medium) {
      min-height: 275px; // needs to be responsive

    }

    .hero__text {
      h1, h2, h3, h4, h5, h6 {
        font-weight: inherit;
      }
      font-weight: $global-weight-normal;
      background-color: rgba(0,0,0,0.3);
      color: $white;
      text-shadow:
              -1px -1px 0 $black,
              1px -1px 0 $black,
              -1px  1px 0 $black,
              1px  1px 0 $black;
    }
  }
}