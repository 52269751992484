

$standard-vertical-space: rem-calc(40); // standard vertical margin between sections

$hero-header-font-size: rem-calc(36);

.standard-space-below {
  margin-bottom: $standard-vertical-space;
}

.standard-space-above {
  margin-top: $standard-vertical-space;
}


@mixin color-set($new-color) { // formerly mixin called 'new-color' for Meteor Inkjet
  // takes a color and uses it to set text color in a number of places where it might normally not contrast sufficiently with the background
  color: $new-color;
  // from _base.css
  // Links
  a {
    color: $new-color;
    $anchor-color: rgba($new-color, 0.3); // this is ok for dark colours on light backgrounds, but not the other way around
    text-decoration-color: $anchor-color;
    &:hover {
      //$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
      color: $anchor-color;
      text-decoration-color: scale-color($anchor-color, $lightness: -14%);
    }
  }
  // Blockquotes
  blockquote {
    border-#{$global-left}: 1px solid $new-color;
    &, p {
      color: $new-color;
    }
  }
  // Citations
  cite {
    color: $new-color;
  }
}

// see https://css-tricks.com/snippets/sass/tint-shade-functions/
/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
  @return mix($color, white, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
  @return mix($color, black, $percentage);
}

@import '../_patterns/grid';
@import '../_patterns/strapline';
@import '../_patterns/nav-bar';
@import '../_patterns/top-bar';
@import '../_patterns/header';
@import '../_patterns/footer';
@import '../_patterns/hero';
@import '../_patterns/section';
@import '../_patterns/off-canvas';
@import '../_patterns/card';
@import '../_patterns/media-object';
@import '../_patterns/tabs';
@import '../_patterns/tables';

@import '../_patterns/00-atoms/color';
@import '../_patterns/00-atoms/button';
@import '../_patterns/00-atoms/hhr';
@import '../_patterns/00-atoms/cta';
@import '../_patterns/00-atoms/columns';
@import '../_patterns/00-atoms/badge';
@import '../_patterns/00-atoms/link';
@import '../_patterns/01-molecules/hhr';
@import '../_patterns/01-molecules/20-navigation/concrete5-pagination';
@import '../_patterns/02-organisms/adverts';
@import '../_patterns/02-organisms/at-a-glance';
@import '../_patterns/02-organisms/contact';