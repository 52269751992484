/**
 * This stylesheet is for styles you want to include only when displaying demo
 * styles for grids, animations, color swatches, etc.
 * These styles will not be your production CSS.
 */

$gg-foundation-palette: (
        light-gray: $light-gray,
        medium-gray: $medium-gray,
        dark-gray: $dark-gray,
        black: $black,
        white: $white,
);

#sg-patterns {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  max-width: 100%;
  padding: 0 0.5em;
}

.demo-animate {
  background: #ddd;
  padding: 1em;
  margin-bottom: 1em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.sg-colors {
  display: flex;
  flex-wrap: wrap;
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
  li {
    flex: auto;
    padding: 0.3em;
    margin: 0 0.5em 0.5em 0;
    min-width: 5em;
    max-width: 14em;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
}


.sg-swatch {
  display: block;
  height: 4em;
  margin-bottom: 0.3em;
  border-radius: 5px;

  @each $name, $color in map-merge($foundation-palette, map-merge($gg-foundation-palette, $gg-palette)) {
    &.#{$name} {
      background-color: $color;
    }
  }
}

.sg-label {
  font-size: 90%;
  line-height: 1;
}
