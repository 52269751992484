.read-our-blog {
  background-color: #0a5d99;
  &__head {
    h1 { margin-bottom: 0; }
    color: $white;
    background-image: url('../images/pigment-cloud.jpg');
    background-size: cover;
    background-position: left center;
  }
  &__body {
    background-color: $white;

  }
}

#ad-partner-network-1 {
  position: relative;
  color: white;
  background-color: #404041;
  overflow: hidden;
  z-index: 0;

  &::before, &::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: 300px; // of the block width (<div>)
    height: 300px; // of the block height (<div>)
    background: url('../images/banners/partner-network-circles-01.svg') no-repeat 0 0;
  }

  &::before {
    left: -150px;
    top: 50%; // vertically center
    transform: translate(-30%, -50%); // see https://css-tricks.com/centering-css-complete-guide/
    background-position-x: 0;
    background-position-y: 0;
  }

  &::after {
    right: -80px;
    top: 0;
    transform: translate(0, -80%) rotate(90deg);
    background-position-x: 100%;
    background-position-y: 0;
  }

  @media print, screen and (min-width: 40em) {
    &::before {
      transform: translate(0, -50%);
    }
    &::after {
      transform: translate(0, -60%) rotate(90deg);
    }
    .line1, .line2, .line3, .line4 {
      //padding-left: 150px;
      //padding-right: 150px;
      padding-left: 15%;
      padding-right: 15%;
    }
  }

  .line1 {
    img {
      height: 49px;
    }
  }

  .line2, .line3 {
    font-size: 24px;
    line-height: 25px;
  }

  .line4 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 5px;
    a {
      color: inherit;
      font-weight: bold;
      text-decoration-color: inherit;
      text-decoration-line: none;
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}

.ad-partner-network-2 {
  position: relative;
  color: white;
  background-color: #12326e;

  font-size: 22px;
  line-height: 1.2;
  font-weight: bold;

  p {
    line-height: inherit;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    display: inline-block;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.1;
  }

  .ad-partner-network-2__image, .ad-partner-network-2__text {
    padding-top: 10px;
    padding-bottom: 10px;
    @media print, screen and (min-width: 40em) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .ad-partner-network-2__image {
    background-color: white;
    @media print, screen and (min-width: 40em) {
      flex: 0 1 231+15+15px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      //text-align: center;
    }
  }

  .ad-partner-network-2__text {
    //background-color: #12326e;
    img {
      height: 6rem;
    }
    @media print, screen and (min-width: 40em) {
      font-size: 24px;
      line-height: 1.2;
      font-weight: bold;
    }
  }
}