header {
  font-size: rem-calc(14); // this sets the 'base' font size (in most cases) for header text
}

.top-bar input {
  width: 200px; // this in addition to Foundation's max-width: 200px as there seems to be a glitch, perhaps with display: flex resulting in horizontal offset otherwise.
}

.hamburger {
  @include hamburger($body-font-color, $ggteal, 20px, 12px, 2px, 3); // http://foundation.zurb.com/sites/docs/sass-mixins.html#hamburger
}