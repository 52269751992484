$footer-font-size: rem-calc(14);

footer {
  //margin-top: $global-margin;
  padding-top: $standard-vertical-space;
  @extend .background-teal;
  font-size: $footer-font-size;

  h1, h2, h3, h4, h5, h6 {
    //margin-bottom: $paragraph-lineheight * $footer-font-size;
    color: inherit;
  }

}

.footer-block {
  margin-bottom: $paragraph-lineheight * $footer-font-size;
}

.footer-social-links {
  a {
    font-size: rem-calc(30);
    line-height: 1;
    padding: rem-calc(7);
    display: inline-block;
    border: solid 1px $white;
    &:hover {
      background-color: darken($ggteal, 10%);
    }
  }
}

.sign-off { // should mimic strapline
  background-image: url(../images/pigment-cloud.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  .menu {
    .menu-text {
      padding: 0;
      line-height: $base-line-height;
      font-weight: $global-weight-normal; // override default weight
    }
    a {
      padding-top: 0;
      padding-bottom: 0;
      line-height: $base-line-height;
      text-decoration-line: $anchor-text-decoration; // reinstate default behaviour
      text-decoration-color: rgba($white, 0.5); // reassert color from footer
      &:hover {
        color: $white; // reassert color from footer
        text-decoration-color: $white; // reassert color from footer
        text-decoration-line: $anchor-text-decoration-hover; // reassert color from footer
      }
    }
  }
  &__inner {
    @include grid-row;
    @include grid-column;
    float: none; // override grid-column float: left which breaks this semantic combined row/column
    // sign-off-padding is calculated in the same was as strapline-padding
    $sign-off-padding: (rem-calc(45) - rem-calc($global-lineheight * $global-font-size))/2;
    padding-top: $sign-off-padding;
    padding-bottom: $sign-off-padding;
  }
}