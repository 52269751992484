.top-bar {
  @include grid-row;
  @include grid-column;
  float: none; // override grid-column float: left which breaks this semantic combined row/column
}

.top-bar { // same styling for both menus
  align-items: flex-start; // override Foundation's hard-wired default of center
  .menu { // the horizontal menu bar
    text-transform: uppercase;
    a {
      text-decoration: none; // default $anchor-text-decoration
      &:hover {
        text-decoration: underline; // default $anchor-text-decoration-hover
      }
      padding: 0.3rem 0.7rem; // override $global-menu-padding to save space in .top-bar
      padding: 0.7rem 1rem; // override $global-menu-padding to save space in .top-bar
    }
    .active > a { // override active link coloring and revert text color
      color: $anchor-color;
      background-color: transparent;
      &:hover {
        color: $anchor-color-hover;
      }
    }
  }
  [type='search'] {
    margin-right: 0;
    border: 1px solid $ggteal; // default is $input-border
    box-shadow: inset 0 1px 2px rgba($black, 0.1); // default is $input-shadow
    &:focus {
      border: 1px solid darken($ggteal, 5%); // default is $input-border-focus
      box-shadow: 0 0 5px $ggteal; // default is $input-shadow-focus
    }
  }
  .button {
    font-size: inherit; // override 0.9rem standard button font-size, that is screwing line-height calculation in this context
  }
  // from _settings.scss
  //$input-border: 1px solid $medium-gray;
  //$input-border-focus: 1px solid $dark-gray;
  //$input-shadow: inset 0 1px 2px rgba($black, 0.1);
  //$input-shadow-focus: 0 0 5px $medium-gray;
}

.top-bar-title {
  margin: 0; // override Foundation
  img {
    // limit logo image width
    min-width: rem-calc(230);
  }
}