@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@media print, screen and (min-width: 40em) {
  .reveal.large, .reveal.small, .reveal.tiny, .reveal {
    right: auto;
    left: auto;
    margin: 0 auto;
  }
}
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif; /* 1 */
  line-height: 1.15; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 18px;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-weight: 300;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}
[data-whatinput=mouse] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 66.6666666667rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.row .row {
  margin-right: -0.5555555556rem;
  margin-left: -0.5555555556rem;
}
@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.8333333333rem;
    margin-left: -0.8333333333rem;
  }
}
@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.8333333333rem;
    margin-left: -0.8333333333rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row.is-collapse-child, .row.collapse > .column > .row, .row.collapse > .columns > .row {
  margin-right: 0;
  margin-left: 0;
}

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.5555555556rem;
  padding-left: 0.5555555556rem;
  min-width: 0;
}
@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}

.column.row.row, .row.row.columns {
  float: none;
  display: block;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 {
  flex-wrap: wrap;
}
.small-up-1 > .column, .small-up-1 > .columns {
  flex: 0 0 100%;
  max-width: 100%;
}

.small-up-2 {
  flex-wrap: wrap;
}
.small-up-2 > .column, .small-up-2 > .columns {
  flex: 0 0 50%;
  max-width: 50%;
}

.small-up-3 {
  flex-wrap: wrap;
}
.small-up-3 > .column, .small-up-3 > .columns {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.small-up-4 {
  flex-wrap: wrap;
}
.small-up-4 > .column, .small-up-4 > .columns {
  flex: 0 0 25%;
  max-width: 25%;
}

.small-up-5 {
  flex-wrap: wrap;
}
.small-up-5 > .column, .small-up-5 > .columns {
  flex: 0 0 20%;
  max-width: 20%;
}

.small-up-6 {
  flex-wrap: wrap;
}
.small-up-6 > .column, .small-up-6 > .columns {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.small-up-7 {
  flex-wrap: wrap;
}
.small-up-7 > .column, .small-up-7 > .columns {
  flex: 0 0 14.2857142857%;
  max-width: 14.2857142857%;
}

.small-up-8 {
  flex-wrap: wrap;
}
.small-up-8 > .column, .small-up-8 > .columns {
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.5555555556rem;
  padding-left: 0.5555555556rem;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 {
    flex-wrap: wrap;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .medium-up-2 {
    flex-wrap: wrap;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .medium-up-3 {
    flex-wrap: wrap;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .medium-up-4 {
    flex-wrap: wrap;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .medium-up-5 {
    flex-wrap: wrap;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .medium-up-6 {
    flex-wrap: wrap;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .medium-up-7 {
    flex-wrap: wrap;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .medium-up-8 {
    flex-wrap: wrap;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 40em) and (min-width: 40em) {
  .medium-expand {
    flex: 1 1 0px;
  }
}
.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 40em) {
  .row.medium-unstack > .column, .row.medium-unstack > .columns {
    flex: 1 1 0px;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}

@media print, screen and (min-width: 64em) {
  .large-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 {
    flex-wrap: wrap;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .large-up-2 {
    flex-wrap: wrap;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .large-up-3 {
    flex-wrap: wrap;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .large-up-4 {
    flex-wrap: wrap;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .large-up-5 {
    flex-wrap: wrap;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .large-up-6 {
    flex-wrap: wrap;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .large-up-7 {
    flex-wrap: wrap;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    flex: 0 0 14.2857142857%;
    max-width: 14.2857142857%;
  }
  .large-up-8 {
    flex-wrap: wrap;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
}
@media print, screen and (min-width: 64em) and (min-width: 64em) {
  .large-expand {
    flex: 1 1 0px;
  }
}
.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%;
}
@media print, screen and (min-width: 64em) {
  .row.large-unstack > .column, .row.large-unstack > .columns {
    flex: 1 1 0px;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}

.shrink {
  flex: 0 0 auto;
  max-width: 100%;
}

.column-block {
  margin-bottom: 1.1111111111rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.6666666667rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 0.5555555556rem;
  font-size: inherit;
  line-height: 1.44;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: inherit;
  text-rendering: optimizeLegibility;
}
h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #cacaca;
}

h1, .h1 {
  font-size: 1.4444444444rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 1.2222222222rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 1.1666666667rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.1111111111rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0555555556rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.2;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 3.0555555556rem;
  }
  h2, .h2 {
    font-size: 2.4444444444rem;
  }
  h3, .h3 {
    font-size: 1.9444444444rem;
  }
  h4, .h4 {
    font-size: 1.5555555556rem;
  }
  h5, .h5 {
    font-size: 1.2222222222rem;
  }
  h6, .h6 {
    font-size: 1rem;
  }
}
a {
  line-height: inherit;
  color: #12326e;
  text-decoration: underline;
  cursor: pointer;
}
a:hover, a:focus {
  color: #0f2b5f;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 66.6666666667rem;
  height: 0;
  margin: 1.1111111111rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 0.5555555556rem;
  list-style-position: outside;
  line-height: 1.44;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}

blockquote {
  margin: 0 0 0.5555555556rem;
  padding: 0.5rem 1.1111111111rem 0 1.0555555556rem;
  border-left: 1px solid #cacaca;
}
blockquote, blockquote p {
  line-height: 1.44;
  color: #8a8a8a;
}

cite {
  display: block;
  font-size: 0.7222222222rem;
  color: #8a8a8a;
}
cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #0a0a0a;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.1111111111rem 0.2777777778rem 0.0555555556rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 300;
  color: #0a0a0a;
}

kbd {
  margin: 0;
  padding: 0.1111111111rem 0.2222222222rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #0a0a0a;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  line-height: 1.2;
  color: #8a8a8a;
}

.lead {
  font-size: 24px;
  line-height: 1.25;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}
.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0.5555555556rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #12326e;
  color: #fefefe;
}
[data-whatinput=mouse] .button {
  outline: 0;
}
.button:hover, .button:focus {
  background-color: #0f2b5e;
  color: #fefefe;
}
.button.tiny {
  font-size: 0.6rem;
}
.button.small {
  font-size: 0.75rem;
}
.button.large {
  font-size: 1.25rem;
}
.button.expanded {
  display: block;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}
.button.primary {
  background-color: #12326e;
  color: #fefefe;
}
.button.primary:hover, .button.primary:focus {
  background-color: #0e2858;
  color: #fefefe;
}
.button.secondary {
  background-color: #f07e26;
  color: #0a0a0a;
}
.button.secondary:hover, .button.secondary:focus {
  background-color: #d0630e;
  color: #0a0a0a;
}
.button.success {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button.success:hover, .button.success:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}
.button.warning {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button.warning:hover, .button.warning:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}
.button.alert {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.alert:hover, .button.alert:focus {
  background-color: #a53b2a;
  color: #fefefe;
}
.button.gg {
  background-color: #12326e;
  color: #fefefe;
}
.button.gg:hover, .button.gg:focus {
  background-color: #0e2858;
  color: #fefefe;
}
.button.gg2 {
  background-color: #f07e26;
  color: #0a0a0a;
}
.button.gg2:hover, .button.gg2:focus {
  background-color: #d0630e;
  color: #0a0a0a;
}
.button.tint {
  background-color: #f2f2f2;
  color: #0a0a0a;
}
.button.tint:hover, .button.tint:focus {
  background-color: #c2c2c2;
  color: #0a0a0a;
}
.button.disabled, .button[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
  background-color: #12326e;
  color: #fefefe;
}
.button.disabled.primary, .button[disabled].primary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
  background-color: #12326e;
  color: #fefefe;
}
.button.disabled.secondary, .button[disabled].secondary {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
  background-color: #f07e26;
  color: #0a0a0a;
}
.button.disabled.success, .button[disabled].success {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button.disabled.warning, .button[disabled].warning {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button.disabled.alert, .button[disabled].alert {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
  background-color: #cc4b37;
  color: #fefefe;
}
.button.disabled.gg, .button[disabled].gg {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.gg, .button.disabled.gg:hover, .button.disabled.gg:focus, .button[disabled].gg, .button[disabled].gg:hover, .button[disabled].gg:focus {
  background-color: #12326e;
  color: #fefefe;
}
.button.disabled.gg2, .button[disabled].gg2 {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.gg2, .button.disabled.gg2:hover, .button.disabled.gg2:focus, .button[disabled].gg2, .button[disabled].gg2:hover, .button[disabled].gg2:focus {
  background-color: #f07e26;
  color: #0a0a0a;
}
.button.disabled.tint, .button[disabled].tint {
  opacity: 0.25;
  cursor: not-allowed;
}
.button.disabled.tint, .button.disabled.tint:hover, .button.disabled.tint:focus, .button[disabled].tint, .button[disabled].tint:hover, .button[disabled].tint:focus {
  background-color: #f2f2f2;
  color: #0a0a0a;
}
.button.hollow {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.hollow, .button.hollow:hover, .button.hollow:focus {
  background-color: transparent;
}
.button.hollow.disabled, .button.hollow.disabled:hover, .button.hollow.disabled:focus, .button.hollow[disabled], .button.hollow[disabled]:hover, .button.hollow[disabled]:focus {
  background-color: transparent;
}
.button.hollow:hover, .button.hollow:focus {
  border-color: #091937;
  color: #091937;
}
.button.hollow:hover.disabled, .button.hollow:hover[disabled], .button.hollow:focus.disabled, .button.hollow:focus[disabled] {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.hollow.primary {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.hollow.primary:hover, .button.hollow.primary:focus {
  border-color: #091937;
  color: #091937;
}
.button.hollow.primary:hover.disabled, .button.hollow.primary:hover[disabled], .button.hollow.primary:focus.disabled, .button.hollow.primary:focus[disabled] {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.hollow.secondary {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.hollow.secondary:hover, .button.hollow.secondary:focus {
  border-color: #823e09;
  color: #823e09;
}
.button.hollow.secondary:hover.disabled, .button.hollow.secondary:hover[disabled], .button.hollow.secondary:focus.disabled, .button.hollow.secondary:focus[disabled] {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.hollow.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.success:hover, .button.hollow.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.hollow.success:hover.disabled, .button.hollow.success:hover[disabled], .button.hollow.success:focus.disabled, .button.hollow.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.hollow.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.warning:hover, .button.hollow.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.hollow.warning:hover.disabled, .button.hollow.warning:hover[disabled], .button.hollow.warning:focus.disabled, .button.hollow.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.hollow.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.alert:hover, .button.hollow.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.hollow.alert:hover.disabled, .button.hollow.alert:hover[disabled], .button.hollow.alert:focus.disabled, .button.hollow.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.hollow.gg {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.hollow.gg:hover, .button.hollow.gg:focus {
  border-color: #091937;
  color: #091937;
}
.button.hollow.gg:hover.disabled, .button.hollow.gg:hover[disabled], .button.hollow.gg:focus.disabled, .button.hollow.gg:focus[disabled] {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.hollow.gg2 {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.hollow.gg2:hover, .button.hollow.gg2:focus {
  border-color: #823e09;
  color: #823e09;
}
.button.hollow.gg2:hover.disabled, .button.hollow.gg2:hover[disabled], .button.hollow.gg2:focus.disabled, .button.hollow.gg2:focus[disabled] {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.hollow.tint {
  border: 1px solid #f2f2f2;
  color: #f2f2f2;
}
.button.hollow.tint:hover, .button.hollow.tint:focus {
  border-color: #797979;
  color: #797979;
}
.button.hollow.tint:hover.disabled, .button.hollow.tint:hover[disabled], .button.hollow.tint:focus.disabled, .button.hollow.tint:focus[disabled] {
  border: 1px solid #f2f2f2;
  color: #f2f2f2;
}
.button.clear {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.clear, .button.clear:hover, .button.clear:focus {
  background-color: transparent;
}
.button.clear.disabled, .button.clear.disabled:hover, .button.clear.disabled:focus, .button.clear[disabled], .button.clear[disabled]:hover, .button.clear[disabled]:focus {
  background-color: transparent;
}
.button.clear:hover, .button.clear:focus {
  border-color: #091937;
  color: #091937;
}
.button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.clear, .button.clear.disabled, .button.clear[disabled], .button.clear:hover, .button.clear:hover.disabled, .button.clear:hover[disabled], .button.clear:focus, .button.clear:focus.disabled, .button.clear:focus[disabled] {
  border-color: transparent;
}
.button.clear.primary {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.clear.primary:hover, .button.clear.primary:focus {
  border-color: #091937;
  color: #091937;
}
.button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.clear.primary, .button.clear.primary.disabled, .button.clear.primary[disabled], .button.clear.primary:hover, .button.clear.primary:hover.disabled, .button.clear.primary:hover[disabled], .button.clear.primary:focus, .button.clear.primary:focus.disabled, .button.clear.primary:focus[disabled] {
  border-color: transparent;
}
.button.clear.secondary {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.clear.secondary:hover, .button.clear.secondary:focus {
  border-color: #823e09;
  color: #823e09;
}
.button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.clear.secondary, .button.clear.secondary.disabled, .button.clear.secondary[disabled], .button.clear.secondary:hover, .button.clear.secondary:hover.disabled, .button.clear.secondary:hover[disabled], .button.clear.secondary:focus, .button.clear.secondary:focus.disabled, .button.clear.secondary:focus[disabled] {
  border-color: transparent;
}
.button.clear.success {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success:hover, .button.clear.success:focus {
  border-color: #157539;
  color: #157539;
}
.button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border: 1px solid #3adb76;
  color: #3adb76;
}
.button.clear.success, .button.clear.success.disabled, .button.clear.success[disabled], .button.clear.success:hover, .button.clear.success:hover.disabled, .button.clear.success:hover[disabled], .button.clear.success:focus, .button.clear.success:focus.disabled, .button.clear.success:focus[disabled] {
  border-color: transparent;
}
.button.clear.warning {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning:hover, .button.clear.warning:focus {
  border-color: #805700;
  color: #805700;
}
.button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border: 1px solid #ffae00;
  color: #ffae00;
}
.button.clear.warning, .button.clear.warning.disabled, .button.clear.warning[disabled], .button.clear.warning:hover, .button.clear.warning:hover.disabled, .button.clear.warning:hover[disabled], .button.clear.warning:focus, .button.clear.warning:focus.disabled, .button.clear.warning:focus[disabled] {
  border-color: transparent;
}
.button.clear.alert {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert:hover, .button.clear.alert:focus {
  border-color: #67251a;
  color: #67251a;
}
.button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border: 1px solid #cc4b37;
  color: #cc4b37;
}
.button.clear.alert, .button.clear.alert.disabled, .button.clear.alert[disabled], .button.clear.alert:hover, .button.clear.alert:hover.disabled, .button.clear.alert:hover[disabled], .button.clear.alert:focus, .button.clear.alert:focus.disabled, .button.clear.alert:focus[disabled] {
  border-color: transparent;
}
.button.clear.gg {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.clear.gg:hover, .button.clear.gg:focus {
  border-color: #091937;
  color: #091937;
}
.button.clear.gg:hover.disabled, .button.clear.gg:hover[disabled], .button.clear.gg:focus.disabled, .button.clear.gg:focus[disabled] {
  border: 1px solid #12326e;
  color: #12326e;
}
.button.clear.gg, .button.clear.gg.disabled, .button.clear.gg[disabled], .button.clear.gg:hover, .button.clear.gg:hover.disabled, .button.clear.gg:hover[disabled], .button.clear.gg:focus, .button.clear.gg:focus.disabled, .button.clear.gg:focus[disabled] {
  border-color: transparent;
}
.button.clear.gg2 {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.clear.gg2:hover, .button.clear.gg2:focus {
  border-color: #823e09;
  color: #823e09;
}
.button.clear.gg2:hover.disabled, .button.clear.gg2:hover[disabled], .button.clear.gg2:focus.disabled, .button.clear.gg2:focus[disabled] {
  border: 1px solid #f07e26;
  color: #f07e26;
}
.button.clear.gg2, .button.clear.gg2.disabled, .button.clear.gg2[disabled], .button.clear.gg2:hover, .button.clear.gg2:hover.disabled, .button.clear.gg2:hover[disabled], .button.clear.gg2:focus, .button.clear.gg2:focus.disabled, .button.clear.gg2:focus[disabled] {
  border-color: transparent;
}
.button.clear.tint {
  border: 1px solid #f2f2f2;
  color: #f2f2f2;
}
.button.clear.tint:hover, .button.clear.tint:focus {
  border-color: #797979;
  color: #797979;
}
.button.clear.tint:hover.disabled, .button.clear.tint:hover[disabled], .button.clear.tint:focus.disabled, .button.clear.tint:focus[disabled] {
  border: 1px solid #f2f2f2;
  color: #f2f2f2;
}
.button.clear.tint, .button.clear.tint.disabled, .button.clear.tint[disabled], .button.clear.tint:hover, .button.clear.tint:hover.disabled, .button.clear.tint:hover[disabled], .button.clear.tint:focus, .button.clear.tint:focus.disabled, .button.clear.tint:focus[disabled] {
  border-color: transparent;
}
.button.dropdown::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.4em;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #fefefe transparent transparent;
  position: relative;
  top: 0.4em;
  display: inline-block;
  float: right;
  margin-left: 1em;
}
.button.dropdown.hollow::after {
  border-top-color: #12326e;
}
.button.dropdown.hollow.primary::after {
  border-top-color: #12326e;
}
.button.dropdown.hollow.secondary::after {
  border-top-color: #f07e26;
}
.button.dropdown.hollow.success::after {
  border-top-color: #3adb76;
}
.button.dropdown.hollow.warning::after {
  border-top-color: #ffae00;
}
.button.dropdown.hollow.alert::after {
  border-top-color: #cc4b37;
}
.button.dropdown.hollow.gg::after {
  border-top-color: #12326e;
}
.button.dropdown.hollow.gg2::after {
  border-top-color: #f07e26;
}
.button.dropdown.hollow.tint::after {
  border-top-color: #f2f2f2;
}
.button.arrow-only::after {
  top: -0.1em;
  float: none;
  margin-left: 0;
}

a.button:hover, a.button:focus {
  text-decoration: none;
}

[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.3333333333rem;
  margin: 0 0 0.8888888889rem;
  padding: 0.4444444444rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #0a0a0a;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #cacaca;
}
input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed;
}

[type=submit],
[type=button] {
  appearance: none;
  border-radius: 0;
}

input[type=search] {
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 0.8888888889rem;
}

[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.4444444444rem;
  margin-right: 0.8888888889rem;
  margin-bottom: 0;
}
[type=checkbox] + label[for],
[type=radio] + label[for] {
  cursor: pointer;
}

label > [type=checkbox],
label > [type=radio] {
  margin-right: 0.4444444444rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.7777777778rem;
  font-weight: 300;
  line-height: 1.8;
  color: #0a0a0a;
}
label.middle {
  margin: 0 0 0.8888888889rem;
  padding: 0.5rem 0;
}

.help-text {
  margin-top: -0.4444444444rem;
  font-size: 0.7222222222rem;
  font-style: italic;
  color: #0a0a0a;
}

.input-group {
  display: flex;
  width: 100%;
  margin-bottom: 0.8888888889rem;
  align-items: stretch;
}
.input-group > :first-child {
  border-radius: 0 0 0 0;
}
.input-group > :last-child > * {
  border-radius: 0 0 0 0;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  margin: 0;
  white-space: nowrap;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #0a0a0a;
  text-align: center;
  white-space: nowrap;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  flex: 1 1 0px;
  height: auto;
  min-width: 0;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  display: flex;
  flex: 0 0 auto;
}
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: auto;
  align-self: stretch;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.4444444444rem;
}

.fieldset {
  margin: 1rem 0;
  padding: 1.1111111111rem;
  border: 1px solid #cacaca;
}
.fieldset legend {
  margin: 0;
  margin-left: -0.1666666667rem;
  padding: 0 0.1666666667rem;
}

select {
  height: 2.3333333333rem;
  margin: 0 0 0.8888888889rem;
  padding: 0.4444444444rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: #0a0a0a;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -0.8888888889rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.3333333333rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select:focus {
  outline: none;
  border: 1px solid #8a8a8a;
  background-color: #fefefe;
  box-shadow: 0 0 5px #cacaca;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  background-color: #e6e6e6;
  cursor: not-allowed;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #cc4b37;
}

.is-invalid-label {
  color: #cc4b37;
}

.form-error {
  display: none;
  margin-top: -0.4444444444rem;
  margin-bottom: 0.8888888889rem;
  font-size: 0.6666666667rem;
  font-weight: bold;
  color: #cc4b37;
}
.form-error.is-visible {
  display: block;
}

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none;
}
.accordion[disabled] .accordion-title {
  cursor: not-allowed;
}

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0;
}
.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 0.6666666667rem;
  line-height: 1;
  color: #12326e;
}
:last-child:not(.is-active) > .accordion-title {
  border-bottom: 1px solid #e6e6e6;
  border-radius: 0 0 0 0;
}
.accordion-title:hover, .accordion-title:focus {
  background-color: #e6e6e6;
}
.accordion-title::before {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: -0.5rem;
  content: "+";
}
.is-active > .accordion-title::before {
  content: "–";
}

.accordion-content {
  display: none;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  background-color: #fefefe;
  color: #0a0a0a;
}
:last-child > .accordion-content:last-child {
  border-bottom: 1px solid #e6e6e6;
}

.accordion-menu li {
  width: 100%;
}
.accordion-menu a {
  padding: 0.7rem 1rem;
}
.accordion-menu .is-accordion-submenu a {
  padding: 0.7rem 1rem;
}
.accordion-menu .nested.is-accordion-submenu {
  margin-right: 0;
  margin-left: 1rem;
}
.accordion-menu.align-right .nested.is-accordion-submenu {
  margin-right: 1rem;
  margin-left: 0;
}
.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a {
  position: relative;
}
.accordion-menu .is-accordion-submenu-parent:not(.has-submenu-toggle) > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #12326e transparent transparent;
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 1rem;
}
.accordion-menu.align-left .is-accordion-submenu-parent > a::after {
  left: auto;
  right: 1rem;
}
.accordion-menu.align-right .is-accordion-submenu-parent > a::after {
  right: auto;
  left: 1rem;
}
.accordion-menu .is-accordion-submenu-parent[aria-expanded=true] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%;
}

.is-accordion-submenu-parent {
  position: relative;
}

.has-submenu-toggle > a {
  margin-right: 40px;
}

.submenu-toggle {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.submenu-toggle::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #12326e transparent transparent;
  top: 0;
  bottom: 0;
  margin: auto;
}

.submenu-toggle[aria-expanded=true]::after {
  transform: scaleY(-1);
  transform-origin: 50% 50%;
}

.submenu-toggle-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #12326e;
  color: #fefefe;
}
.badge.primary {
  background: #12326e;
  color: #fefefe;
}
.badge.secondary {
  background: #f07e26;
  color: #0a0a0a;
}
.badge.success {
  background: #3adb76;
  color: #0a0a0a;
}
.badge.warning {
  background: #ffae00;
  color: #0a0a0a;
}
.badge.alert {
  background: #cc4b37;
  color: #fefefe;
}

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none;
}
.breadcrumbs::before, .breadcrumbs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.breadcrumbs::after {
  clear: both;
}
.breadcrumbs li {
  float: left;
  font-size: 0.6111111111rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}
.breadcrumbs li:not(:last-child)::after {
  position: relative;
  margin: 0 0.75rem;
  opacity: 1;
  content: "/";
  color: #cacaca;
}
.breadcrumbs a {
  color: #12326e;
}
.breadcrumbs a:hover {
  text-decoration: underline;
}
.breadcrumbs .disabled {
  color: #cacaca;
  cursor: not-allowed;
}

.button-group {
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
}
.button-group::before, .button-group::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.button-group::after {
  clear: both;
}
.button-group .button {
  margin: 0;
  margin-right: 1px;
  margin-bottom: 1px;
  font-size: 0.9rem;
  flex: 0 0 auto;
}
.button-group .button:last-child {
  margin-right: 0;
}
.button-group.tiny .button {
  font-size: 0.6rem;
}
.button-group.small .button {
  font-size: 0.75rem;
}
.button-group.large .button {
  font-size: 1.25rem;
}
.button-group.expanded .button {
  flex: 1 1 0px;
}
.button-group.primary .button {
  background-color: #12326e;
  color: #fefefe;
}
.button-group.primary .button:hover, .button-group.primary .button:focus {
  background-color: #0e2858;
  color: #fefefe;
}
.button-group.secondary .button {
  background-color: #f07e26;
  color: #0a0a0a;
}
.button-group.secondary .button:hover, .button-group.secondary .button:focus {
  background-color: #d0630e;
  color: #0a0a0a;
}
.button-group.success .button {
  background-color: #3adb76;
  color: #0a0a0a;
}
.button-group.success .button:hover, .button-group.success .button:focus {
  background-color: #22bb5b;
  color: #0a0a0a;
}
.button-group.warning .button {
  background-color: #ffae00;
  color: #0a0a0a;
}
.button-group.warning .button:hover, .button-group.warning .button:focus {
  background-color: #cc8b00;
  color: #0a0a0a;
}
.button-group.alert .button {
  background-color: #cc4b37;
  color: #fefefe;
}
.button-group.alert .button:hover, .button-group.alert .button:focus {
  background-color: #a53b2a;
  color: #fefefe;
}
.button-group.stacked, .button-group.stacked-for-small, .button-group.stacked-for-medium {
  flex-wrap: wrap;
}
.button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
  flex: 0 0 100%;
}
.button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .button-group.stacked-for-small .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .button-group.stacked-for-medium .button {
    flex: 1 1 0px;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 39.9375em) {
  .button-group.stacked-for-small.expanded {
    display: block;
  }
  .button-group.stacked-for-small.expanded .button {
    display: block;
    margin-right: 0;
  }
}

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: none;
  border-radius: 0;
  background-color: #f8f8f8;
  color: #0a0a0a;
}
.callout > :first-child {
  margin-top: 0;
}
.callout > :last-child {
  margin-bottom: 0;
}
.callout.primary {
  background-color: #cedcf7;
  color: #0a0a0a;
}
.callout.secondary {
  background-color: #fdecde;
  color: #0a0a0a;
}
.callout.success {
  background-color: #e1faea;
  color: #0a0a0a;
}
.callout.warning {
  background-color: #fff3d9;
  color: #0a0a0a;
}
.callout.alert {
  background-color: #f7e4e1;
  color: #0a0a0a;
}
.callout.small {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}
.callout.large {
  padding-top: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
}

.card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 1rem;
  border: none;
  border-radius: 0;
  background: #fefefe;
  box-shadow: none;
  overflow: hidden;
  color: #0a0a0a;
}
.card > :last-child {
  margin-bottom: 0;
}

.card-divider {
  flex: 0 1 auto;
  display: flex;
  padding: 1rem;
  background: #e6e6e6;
}
.card-divider > :last-child {
  margin-bottom: 0;
}

.card-section {
  flex: 1 0 auto;
  padding: 1rem;
}
.card-section > :last-child {
  margin-bottom: 0;
}

.card-image {
  min-height: 1px;
}

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer;
}
[data-whatinput=mouse] .close-button {
  outline: 0;
}
.close-button:hover, .close-button:focus {
  color: #0a0a0a;
}
.close-button.small {
  right: 0.66rem;
  top: 0.33em;
  font-size: 1.5em;
  line-height: 1;
}
.close-button, .close-button.medium {
  right: 1rem;
  top: 0.5rem;
  font-size: 2em;
  line-height: 1;
}

.menu {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
[data-whatinput=mouse] .menu li {
  outline: 0;
}
.menu a,
.menu .button {
  line-height: 1;
  text-decoration: none;
  display: block;
  padding: 0.7rem 1rem;
}
.menu input,
.menu select,
.menu a,
.menu button {
  margin-bottom: 0;
}
.menu input {
  display: inline-block;
}
.menu, .menu.horizontal {
  flex-wrap: wrap;
  flex-direction: row;
}
.menu.vertical {
  flex-wrap: nowrap;
  flex-direction: column;
}
.menu.expanded li {
  flex: 1 1 0px;
}
.menu.simple {
  align-items: center;
}
.menu.simple li + li {
  margin-left: 1rem;
}
.menu.simple a {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .menu.medium-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.medium-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.medium-expanded li {
    flex: 1 1 0px;
  }
  .menu.medium-simple li {
    flex: 1 1 0px;
  }
}
@media print, screen and (min-width: 64em) {
  .menu.large-horizontal {
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menu.large-vertical {
    flex-wrap: nowrap;
    flex-direction: column;
  }
  .menu.large-expanded li {
    flex: 1 1 0px;
  }
  .menu.large-simple li {
    flex: 1 1 0px;
  }
}
.menu.nested {
  margin-right: 0;
  margin-left: 1rem;
}
.menu.icons a {
  display: flex;
}
.menu.icon-top a, .menu.icon-right a, .menu.icon-bottom a, .menu.icon-left a {
  display: flex;
}
.menu.icon-left li a {
  flex-flow: row nowrap;
}
.menu.icon-left li a img,
.menu.icon-left li a i,
.menu.icon-left li a svg {
  margin-right: 0.25rem;
}
.menu.icon-right li a {
  flex-flow: row nowrap;
}
.menu.icon-right li a img,
.menu.icon-right li a i,
.menu.icon-right li a svg {
  margin-left: 0.25rem;
}
.menu.icon-top li a {
  flex-flow: column nowrap;
}
.menu.icon-top li a img,
.menu.icon-top li a i,
.menu.icon-top li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu.icon-bottom li a {
  flex-flow: column nowrap;
}
.menu.icon-bottom li a img,
.menu.icon-bottom li a i,
.menu.icon-bottom li a svg {
  align-self: stretch;
  margin-bottom: 0.25rem;
  text-align: center;
}
.menu .is-active > a {
  background: #12326e;
  color: #fefefe;
}
.menu .active > a {
  background: #12326e;
  color: #fefefe;
}
.menu.align-left {
  justify-content: flex-start;
}
.menu.align-right li {
  display: flex;
  justify-content: flex-end;
}
.menu.align-right li .submenu li {
  justify-content: flex-start;
}
.menu.align-right.vertical li {
  display: block;
  text-align: right;
}
.menu.align-right.vertical li .submenu li {
  text-align: right;
}
.menu.align-right .nested {
  margin-right: 1rem;
  margin-left: 0;
}
.menu.align-center li {
  display: flex;
  justify-content: center;
}
.menu.align-center li .submenu li {
  justify-content: flex-start;
}
.menu .menu-text {
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit;
}

.menu-centered > .menu {
  justify-content: center;
}
.menu-centered > .menu li {
  display: flex;
  justify-content: center;
}
.menu-centered > .menu li .submenu li {
  justify-content: flex-start;
}

.no-js [data-responsive-menu] ul {
  display: none;
}

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.menu-icon::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #fefefe;
  box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
  content: "";
}
.menu-icon:hover::after {
  background: #cacaca;
  box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca;
}

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer;
}
.menu-icon.dark::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 7px 0 #0a0a0a, 0 14px 0 #0a0a0a;
  content: "";
}
.menu-icon.dark:hover::after {
  background: #8a8a8a;
  box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a;
}

.is-drilldown {
  position: relative;
  overflow: hidden;
}
.is-drilldown li {
  display: block;
}
.is-drilldown.animate-height {
  transition: height 0.5s;
}

.drilldown a {
  padding: 0.7rem 1rem;
  background: #fefefe;
}
.drilldown .is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear;
}
.drilldown .is-drilldown-submenu.is-active {
  z-index: 1;
  display: block;
  transform: translateX(-100%);
}
.drilldown .is-drilldown-submenu.is-closing {
  transform: translateX(100%);
}
.drilldown .is-drilldown-submenu a {
  padding: 0.7rem 1rem;
}
.drilldown .nested.is-drilldown-submenu {
  margin-right: 0;
  margin-left: 0;
}
.drilldown .drilldown-submenu-cover-previous {
  min-height: 100%;
}
.drilldown .is-drilldown-submenu-parent > a {
  position: relative;
}
.drilldown .is-drilldown-submenu-parent > a::after {
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #12326e;
}
.drilldown.align-left .is-drilldown-submenu-parent > a::after {
  left: auto;
  right: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #12326e;
}
.drilldown.align-right .is-drilldown-submenu-parent > a::after {
  right: auto;
  left: 1rem;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #12326e transparent transparent;
}
.drilldown .js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #12326e transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0;
}

.dropdown-pane {
  position: absolute;
  z-index: 10;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  display: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-size: 1rem;
}
.dropdown-pane.is-opening {
  display: block;
}
.dropdown-pane.is-open {
  visibility: visible;
  display: block;
}

.dropdown-pane.tiny {
  width: 100px;
}

.dropdown-pane.small {
  width: 200px;
}

.dropdown-pane.large {
  width: 400px;
}

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto;
}
.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem;
}
.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #12326e transparent transparent;
  right: 5px;
  left: auto;
  margin-top: -3px;
}
.dropdown.menu a {
  padding: 0.7rem 1rem;
}
[data-whatinput=mouse] .dropdown.menu a {
  outline: 0;
}
.dropdown.menu .is-active > a {
  background: #fefefe;
  color: #12326e;
}
.no-js .dropdown.menu ul {
  display: none;
}
.dropdown.menu .nested.is-dropdown-submenu {
  margin-right: 0;
  margin-left: 0;
}
.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0;
}
.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
  top: 0;
}
.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}
.dropdown.menu.vertical > li > a::after {
  right: 14px;
}
.dropdown.menu.vertical > li.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #12326e transparent transparent;
}
.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #12326e;
}
@media print, screen and (min-width: 40em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #12326e transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #12326e transparent transparent;
  }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #12326e;
  }
}
@media print, screen and (min-width: 64em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto;
  }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem;
  }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #12326e transparent transparent;
    right: 5px;
    left: auto;
    margin-top: -3px;
  }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto;
    top: 0;
  }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%;
  }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px;
  }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    right: auto;
    left: 5px;
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #12326e transparent transparent;
  }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: "";
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #12326e;
  }
}
.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto;
}

.is-dropdown-menu.vertical {
  width: 100px;
}
.is-dropdown-menu.vertical.align-right {
  float: right;
}

.is-dropdown-submenu-parent {
  position: relative;
}
.is-dropdown-submenu-parent a::after {
  position: absolute;
  top: 50%;
  right: 5px;
  left: auto;
  margin-top: -6px;
}
.is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
  top: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto;
}
.is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%;
}

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: none;
  background: rgba(254, 254, 254, 0.95);
}
.dropdown .is-dropdown-submenu a {
  padding: 0.7rem 1rem;
}
.is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
  right: 14px;
}
.is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
  right: auto;
  left: 5px;
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #12326e transparent transparent;
}
.is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #12326e;
}
.is-dropdown-submenu > li {
  width: 100%;
}
.is-dropdown-submenu.js-dropdown-active {
  display: block;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 0.8888888889rem;
  padding-bottom: 75%;
  overflow: hidden;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #12326e;
  color: #fefefe;
}
.label.primary {
  background: #12326e;
  color: #fefefe;
}
.label.secondary {
  background: #f07e26;
  color: #0a0a0a;
}
.label.success {
  background: #3adb76;
  color: #0a0a0a;
}
.label.warning {
  background: #ffae00;
  color: #0a0a0a;
}
.label.alert {
  background: #cc4b37;
  color: #fefefe;
}

.media-object {
  display: flex;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}
.media-object img {
  max-width: none;
}
@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 39.9375em) {
  .media-object.stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    flex-basis: 100%;
    max-width: 100%;
  }
  .media-object.stack-for-small .media-object-section img {
    width: 100%;
  }
}

.media-object-section {
  flex: 0 1 auto;
}
.media-object-section:first-child {
  padding-right: 1rem;
}
.media-object-section:last-child:not(:nth-child(2)) {
  padding-left: 1rem;
}
.media-object-section > :last-child {
  margin-bottom: 0;
}
.media-object-section.main-section {
  flex: 1 1 0px;
}

.is-off-canvas-open {
  overflow: hidden;
}

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}
.js-off-canvas-overlay.is-visible {
  opacity: 1;
  visibility: visible;
}
.js-off-canvas-overlay.is-closable {
  cursor: pointer;
}
.js-off-canvas-overlay.is-overlay-absolute {
  position: absolute;
}
.js-off-canvas-overlay.is-overlay-fixed {
  position: fixed;
}

.off-canvas-wrapper {
  position: relative;
  overflow: hidden;
}

.off-canvas {
  position: fixed;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #12326e;
}
[data-whatinput=mouse] .off-canvas {
  outline: 0;
}
.off-canvas.is-transition-push {
  z-index: 12;
}
.off-canvas.is-closed {
  visibility: hidden;
}
.off-canvas.is-transition-overlap {
  z-index: 13;
}
.off-canvas.is-transition-overlap.is-open {
  box-shadow: none;
}
.off-canvas.is-open {
  transform: translate(0, 0);
}
.off-canvas-absolute {
  position: absolute;
  z-index: 12;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #12326e;
}
[data-whatinput=mouse] .off-canvas-absolute {
  outline: 0;
}
.off-canvas-absolute.is-transition-push {
  z-index: 12;
}
.off-canvas-absolute.is-closed {
  visibility: hidden;
}
.off-canvas-absolute.is-transition-overlap {
  z-index: 13;
}
.off-canvas-absolute.is-transition-overlap.is-open {
  box-shadow: none;
}
.off-canvas-absolute.is-open {
  transform: translate(0, 0);
}

.position-left {
  top: 0;
  left: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(-250px);
}
.off-canvas-content .off-canvas.position-left {
  transform: translateX(-250px);
}
.off-canvas-content .off-canvas.position-left.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-left.has-transition-push {
  transform: translateX(250px);
}

.position-left.is-transition-push {
  box-shadow: inset -13px 0 20px -13px rgba(10, 10, 10, 0.25);
}

.position-right {
  top: 0;
  right: 0;
  height: 100%;
  overflow-y: auto;
  width: 250px;
  transform: translateX(250px);
}
.off-canvas-content .off-canvas.position-right {
  transform: translateX(250px);
}
.off-canvas-content .off-canvas.position-right.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-right.has-transition-push {
  transform: translateX(-250px);
}

.position-right.is-transition-push {
  box-shadow: inset 13px 0 20px -13px rgba(10, 10, 10, 0.25);
}

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(-250px);
}
.off-canvas-content .off-canvas.position-top {
  transform: translateY(-250px);
}
.off-canvas-content .off-canvas.position-top.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-top.has-transition-push {
  transform: translateY(250px);
}

.position-top.is-transition-push {
  box-shadow: inset 0 -13px 20px -13px rgba(10, 10, 10, 0.25);
}

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-x: auto;
  height: 250px;
  transform: translateY(250px);
}
.off-canvas-content .off-canvas.position-bottom {
  transform: translateY(250px);
}
.off-canvas-content .off-canvas.position-bottom.is-transition-overlap.is-open {
  transform: translate(0, 0);
}

.off-canvas-content.is-open-bottom.has-transition-push {
  transform: translateY(-250px);
}

.position-bottom.is-transition-push {
  box-shadow: inset 0 13px 20px -13px rgba(10, 10, 10, 0.25);
}

.off-canvas-content {
  transform: none;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
}
.off-canvas-content.has-transition-push {
  transform: translate(0, 0);
}
.off-canvas-content .off-canvas.is-open {
  transform: translate(0, 0);
}

@media print, screen and (min-width: 40em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }
  .position-left.reveal-for-medium ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }
  .position-right.reveal-for-medium ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }
  .position-top.reveal-for-medium ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-medium .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-medium {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }
  .position-bottom.reveal-for-medium ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 64em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-left.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-left.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-left {
    margin-left: 250px;
  }
  .position-left.reveal-for-large ~ .off-canvas-content {
    margin-left: 250px;
  }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-right.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-right.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-right {
    margin-right: 250px;
  }
  .position-right.reveal-for-large ~ .off-canvas-content {
    margin-right: 250px;
  }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-top.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-top.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-top {
    margin-top: 250px;
  }
  .position-top.reveal-for-large ~ .off-canvas-content {
    margin-top: 250px;
  }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 12;
    transition: none;
    visibility: visible;
  }
  .position-bottom.reveal-for-large .close-button {
    display: none;
  }
  .off-canvas-content .position-bottom.reveal-for-large {
    transform: none;
  }
  .off-canvas-content.has-reveal-bottom {
    margin-bottom: 250px;
  }
  .position-bottom.reveal-for-large ~ .off-canvas-content {
    margin-bottom: 250px;
  }
}
@media print, screen and (min-width: 40em) {
  .off-canvas.in-canvas-for-medium {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-medium.position-left, .off-canvas.in-canvas-for-medium.position-right, .off-canvas.in-canvas-for-medium.position-top, .off-canvas.in-canvas-for-medium.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-medium .close-button {
    display: none;
  }
}
@media print, screen and (min-width: 64em) {
  .off-canvas.in-canvas-for-large {
    visibility: visible;
    height: auto;
    position: static;
    background: inherit;
    width: inherit;
    overflow: inherit;
    transition: inherit;
  }
  .off-canvas.in-canvas-for-large.position-left, .off-canvas.in-canvas-for-large.position-right, .off-canvas.in-canvas-for-large.position-top, .off-canvas.in-canvas-for-large.position-bottom {
    box-shadow: none;
    transform: none;
  }
  .off-canvas.in-canvas-for-large .close-button {
    display: none;
  }
}
.orbit {
  position: relative;
}

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden;
}

.orbit-slide {
  width: 100%;
}
.orbit-slide.no-motionui.is-active {
  top: 0;
  left: 0;
}

.orbit-figure {
  margin: 0;
}

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0;
}

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(10, 10, 10, 0.5);
  color: #fefefe;
}

.orbit-next, .orbit-previous {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe;
}
[data-whatinput=mouse] .orbit-next, [data-whatinput=mouse] .orbit-previous {
  outline: 0;
}
.orbit-next:hover, .orbit-previous:hover, .orbit-next:active, .orbit-previous:active, .orbit-next:focus, .orbit-previous:focus {
  background-color: rgba(10, 10, 10, 0.5);
}

.orbit-previous {
  left: 0;
}

.orbit-next {
  left: auto;
  right: 0;
}

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center;
}
[data-whatinput=mouse] .orbit-bullets {
  outline: 0;
}
.orbit-bullets button {
  width: 1.2rem;
  height: 1.2rem;
  margin: 0.1rem;
  border-radius: 50%;
  background-color: #cacaca;
}
.orbit-bullets button:hover {
  background-color: #8a8a8a;
}
.orbit-bullets button.is-active {
  background-color: #8a8a8a;
}

.pagination {
  margin-left: 0;
  margin-bottom: 1rem;
}
.pagination::before, .pagination::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.pagination::after {
  clear: both;
}
.pagination li {
  margin-right: 0.0555555556rem;
  border-radius: 0;
  font-size: 0.7777777778rem;
  display: none;
}
.pagination li:last-child, .pagination li:first-child {
  display: inline-block;
}
@media print, screen and (min-width: 40em) {
  .pagination li {
    display: inline-block;
  }
}
.pagination a,
.pagination button {
  display: block;
  padding: 0.1666666667rem 0.5555555556rem;
  border-radius: 0;
  color: #0a0a0a;
}
.pagination a:hover,
.pagination button:hover {
  background: #e6e6e6;
}
.pagination .current {
  padding: 0.1666666667rem 0.5555555556rem;
  background: #12326e;
  color: #fefefe;
  cursor: default;
}
.pagination .disabled {
  padding: 0.1666666667rem 0.5555555556rem;
  color: #cacaca;
  cursor: not-allowed;
}
.pagination .disabled:hover {
  background: transparent;
}
.pagination .ellipsis::after {
  padding: 0.1666666667rem 0.5555555556rem;
  content: "…";
  color: #0a0a0a;
}

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: "«";
}

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: "»";
}

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca;
}
.progress.primary .progress-meter {
  background-color: #12326e;
}
.progress.secondary .progress-meter {
  background-color: #f07e26;
}
.progress.success .progress-meter {
  background-color: #3adb76;
}
.progress.warning .progress-meter {
  background-color: #ffae00;
}
.progress.alert .progress-meter {
  background-color: #cc4b37;
}

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #12326e;
}

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap;
}

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none;
}

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out;
}
.slider-fill.is-dragging {
  transition: all 0s linear;
}

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #12326e;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation;
}
[data-whatinput=mouse] .slider-handle {
  outline: 0;
}
.slider-handle:hover {
  background-color: #0f2b5e;
}
.slider-handle.is-dragging {
  transition: all 0s linear;
}

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed;
}

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1);
}
.slider.vertical .slider-fill {
  top: 0;
  width: 0.5rem;
  max-height: 100%;
}
.slider.vertical .slider-handle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1.4rem;
  height: 1.4rem;
  transform: translateX(-50%);
}

.sticky-container {
  position: relative;
}

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}

.sticky.is-stuck {
  position: fixed;
  z-index: 5;
  width: 100%;
}
.sticky.is-stuck.is-at-top {
  top: 0;
}
.sticky.is-stuck.is-at-bottom {
  bottom: 0;
}

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto;
}
.sticky.is-anchored.is-at-bottom {
  bottom: 0;
}

body.is-reveal-open {
  overflow: hidden;
}

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  position: fixed;
  user-select: none;
}

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: scroll;
}

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto;
}
[data-whatinput=mouse] .reveal {
  outline: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    min-height: 0;
  }
}
.reveal .column, .reveal .columns {
  min-width: 0;
}
.reveal > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal {
    width: 600px;
    max-width: 66.6666666667rem;
  }
}
.reveal.collapse {
  padding: 0;
}
@media print, screen and (min-width: 40em) {
  .reveal.tiny {
    width: 30%;
    max-width: 66.6666666667rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal.small {
    width: 50%;
    max-width: 66.6666666667rem;
  }
}
@media print, screen and (min-width: 40em) {
  .reveal.large {
    width: 90%;
    max-width: 66.6666666667rem;
  }
}
.reveal.full {
  top: 0;
  left: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  height: 100vh;
  min-height: 100vh;
  margin-left: 0;
  border: 0;
  border-radius: 0;
}
@media screen and (max-width: 39.9375em) {
  .reveal {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0;
  }
}
.reveal.without-overlay {
  position: fixed;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.7777777778rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}
input + .switch-paddle {
  margin: 0;
}
.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #fefefe;
  transition: all 0.25s ease-out;
  content: "";
}
input:checked ~ .switch-paddle {
  background: #12326e;
}
input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}
[data-whatinput=mouse] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-inactive, .switch-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}
input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}
input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}
.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.5555555556rem;
}
.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}
.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}
.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.6666666667rem;
}
.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}
.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 0.8888888889rem;
}
.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}
.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}
thead,
tbody,
tfoot {
  border: 1px solid #f1f1f1;
  background-color: #fefefe;
}

caption {
  padding: 0.4444444444rem 0.5555555556rem 0.5555555556rem;
  font-weight: bold;
}

thead {
  background: #f8f8f8;
  color: #0a0a0a;
}

tfoot {
  background: #f1f1f1;
  color: #0a0a0a;
}

thead tr,
tfoot tr {
  background: transparent;
}
thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.4444444444rem 0.5555555556rem 0.5555555556rem;
  font-weight: bold;
  text-align: left;
}

tbody th,
tbody td {
  padding: 0.4444444444rem 0.5555555556rem 0.5555555556rem;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f1f1f1;
}

table.unstriped tbody {
  background-color: #fefefe;
}
table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  background-color: #fefefe;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f3f3f3;
}
table.hover tfoot tr:hover {
  background-color: #ececec;
}
table.hover tbody tr:hover {
  background-color: #f9f9f9;
}
table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec;
}

.table-scroll {
  overflow-x: auto;
}
.table-scroll table {
  width: auto;
}

.tabs {
  margin: 0;
  border: 1px solid #e6e6e6;
  background: #fefefe;
  list-style-type: none;
}
.tabs::before, .tabs::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.tabs::after {
  clear: both;
}

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto;
}

.tabs.simple > li > a {
  padding: 0;
}
.tabs.simple > li > a:hover {
  background: transparent;
}

.tabs.primary {
  background: #12326e;
}
.tabs.primary > li > a {
  color: #fefefe;
}
.tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
  background: #15397e;
}

.tabs-title {
  float: left;
}
.tabs-title > a {
  display: block;
  padding: 1.25rem 1.5rem;
  font-size: 1rem;
  line-height: 1;
  color: #12326e;
}
.tabs-title > a:hover {
  background: #fefefe;
  color: #0f2b5f;
}
.tabs-title > a:focus, .tabs-title > a[aria-selected=true] {
  background: #e6e6e6;
  color: #12326e;
}

.tabs-content {
  border: 1px solid #e6e6e6;
  border-top: 0;
  background: #fefefe;
  color: #0a0a0a;
  transition: all 0.5s ease;
}

.tabs-content.vertical {
  border: 1px solid #e6e6e6;
  border-left: 0;
}

.tabs-panel {
  display: none;
  padding: 1rem;
}
.tabs-panel.is-active {
  display: block;
}

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(10, 10, 10, 0.2);
  line-height: 0;
}

a.thumbnail {
  transition: box-shadow 200ms ease-out;
}
a.thumbnail:hover, a.thumbnail:focus {
  box-shadow: 0 0 6px 1px rgba(18, 50, 110, 0.5);
}
a.thumbnail image {
  box-shadow: none;
}

.title-bar {
  padding: 0.5rem;
  background: #0a0a0a;
  color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-bar .menu-icon {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.title-bar-left,
.title-bar-right {
  flex: 1 1 0px;
}

.title-bar-right {
  text-align: right;
}

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold;
}

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help;
}

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #0a0a0a;
  font-size: 80%;
  color: #fefefe;
}
.tooltip::before {
  position: absolute;
}
.tooltip.bottom::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-top-width: 0;
  border-bottom-style: solid;
  border-color: transparent transparent #0a0a0a;
  bottom: 100%;
}
.tooltip.bottom.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.top::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #0a0a0a transparent transparent;
  top: 100%;
  bottom: auto;
}
.tooltip.top.align-center::before {
  left: 50%;
  transform: translateX(-50%);
}
.tooltip.left::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #0a0a0a;
  left: 100%;
}
.tooltip.left.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip.right::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 0.75rem;
  content: "";
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #0a0a0a transparent transparent;
  right: 100%;
  left: auto;
}
.tooltip.right.align-center::before {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
.tooltip.align-top::before {
  bottom: auto;
  top: 10%;
}
.tooltip.align-bottom::before {
  bottom: 10%;
  top: auto;
}
.tooltip.align-left::before {
  left: 10%;
  right: auto;
}
.tooltip.align-right::before {
  left: auto;
  right: 10%;
}

.top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  flex-wrap: wrap;
  flex-wrap: nowrap;
}
.top-bar,
.top-bar ul {
  background-color: #fefefe;
}
.top-bar input {
  max-width: 200px;
  margin-right: 1rem;
}
.top-bar .input-group-field {
  width: 100%;
  margin-right: 0;
}
.top-bar input.button {
  width: auto;
}
.top-bar .top-bar-left,
.top-bar .top-bar-right {
  flex: 0 0 100%;
  max-width: 100%;
}
.top-bar .top-bar-left {
  flex: 1 1 auto;
  margin-right: auto;
}
.top-bar .top-bar-right {
  flex: 0 1 auto;
  margin-left: auto;
}
@media screen and (max-width: 63.9375em) {
  .top-bar.stacked-for-medium {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-medium .top-bar-left,
  .top-bar.stacked-for-medium .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 74.9375em) {
  .top-bar.stacked-for-large {
    flex-wrap: wrap;
  }
  .top-bar.stacked-for-large .top-bar-left,
  .top-bar.stacked-for-large .top-bar-right {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.top-bar-title {
  flex: 0 0 auto;
  margin: 0.5rem 1rem 0.5rem 0;
}

.top-bar-left,
.top-bar-right {
  flex: 0 0 auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.clearfix::after {
  clear: both;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 40em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}
@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}
.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 40em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}
.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  transition-duration: 750ms !important;
}

.fast {
  transition-duration: 250ms !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  transition-delay: 300ms !important;
}

.long-delay {
  transition-delay: 700ms !important;
}

.shake {
  animation-name: shake-7;
}
@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}

.spin-cw {
  animation-name: spin-cw-1turn;
}
@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }
  100% {
    transform: rotate(0);
  }
}

.spin-ccw {
  animation-name: spin-ccw-1turn;
}
@keyframes spin-ccw-1turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-1turn);
  }
}

.wiggle {
  animation-name: wiggle-7deg;
}
@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }
  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: 750ms !important;
}

.fast {
  animation-duration: 250ms !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  animation-delay: 300ms !important;
}

.long-delay {
  animation-delay: 700ms !important;
}

/**
 * This stylesheet is for styles you want to include only when displaying demo
 * styles for grids, animations, color swatches, etc.
 * These styles will not be your production CSS.
 */
#sg-patterns {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  max-width: 100%;
  padding: 0 0.5em;
}

.demo-animate {
  background: #ddd;
  padding: 1em;
  margin-bottom: 1em;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.sg-colors {
  display: flex;
  flex-wrap: wrap;
  list-style: none !important;
  padding: 0 !important;
  margin: 0 !important;
}
.sg-colors li {
  flex: auto;
  padding: 0.3em;
  margin: 0 0.5em 0.5em 0;
  min-width: 5em;
  max-width: 14em;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.sg-swatch {
  display: block;
  height: 4em;
  margin-bottom: 0.3em;
  border-radius: 5px;
}
.sg-swatch.primary {
  background-color: #12326e;
}
.sg-swatch.secondary {
  background-color: #f07e26;
}
.sg-swatch.success {
  background-color: #3adb76;
}
.sg-swatch.warning {
  background-color: #ffae00;
}
.sg-swatch.alert {
  background-color: #cc4b37;
}
.sg-swatch.light-gray {
  background-color: #e6e6e6;
}
.sg-swatch.medium-gray {
  background-color: #cacaca;
}
.sg-swatch.dark-gray {
  background-color: #8a8a8a;
}
.sg-swatch.black {
  background-color: #0a0a0a;
}
.sg-swatch.white {
  background-color: #fefefe;
}
.sg-swatch.gg {
  background-color: #12326e;
}
.sg-swatch.gg2 {
  background-color: #f07e26;
}
.sg-swatch.tint {
  background-color: #f2f2f2;
}

.sg-label {
  font-size: 90%;
  line-height: 1;
}

.standard-space-below {
  margin-bottom: 2.2222222222rem;
}

.standard-space-above {
  margin-top: 2.2222222222rem;
}

.strapline {
  clear: both;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.strapline__text {
  max-width: 66.6666666667rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.5555555556rem;
  padding-left: 0.5555555556rem;
  float: none;
}
.strapline__text::before, .strapline__text::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.strapline__text::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .strapline__text {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}
.strapline__text:last-child:not(:first-child) {
  float: right;
}

.nav-bar {
  max-width: 66.6666666667rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.5555555556rem;
  padding-left: 0.5555555556rem;
  float: none;
}
.nav-bar::before, .nav-bar::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.nav-bar::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .nav-bar {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}
.nav-bar:last-child:not(:first-child) {
  float: right;
}

.nav-bar .menu {
  text-transform: uppercase;
}
.nav-bar .menu .submenu {
  text-transform: none;
}
.nav-bar .menu .submenu a {
  border-color: transparent;
}
.nav-bar .menu .submenu a:hover {
  transition: border-color 0.5s ease;
  border-left: 5px #f07e26 solid;
}
.nav-bar .menu a {
  text-decoration: none;
}
.nav-bar .menu a:hover {
  background-color: #fefefe;
}
.nav-bar .menu .active > a {
  color: #12326e;
  background-color: transparent;
}
.nav-bar .menu .active > a:hover {
  color: #0f2b5f;
}
.nav-bar.v2 .menu .submenu a {
  color: #0a0a0a;
}

.top-bar {
  max-width: 66.6666666667rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.5555555556rem;
  padding-left: 0.5555555556rem;
  float: none;
}
.top-bar::before, .top-bar::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.top-bar::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .top-bar {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}
.top-bar:last-child:not(:first-child) {
  float: right;
}

.top-bar {
  align-items: flex-start;
}
.top-bar .menu {
  text-transform: uppercase;
}
.top-bar .menu a {
  text-decoration: none;
  padding: 0.3rem 0.7rem;
  padding: 0.7rem 1rem;
}
.top-bar .menu a:hover {
  text-decoration: underline;
}
.top-bar .menu .active > a {
  color: #12326e;
  background-color: transparent;
}
.top-bar .menu .active > a:hover {
  color: #0f2b5f;
}
.top-bar [type=search] {
  margin-right: 0;
  border: 1px solid #12326e;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
}
.top-bar [type=search]:focus {
  border: 1px solid #0e2858;
  box-shadow: 0 0 5px #12326e;
}
.top-bar .button {
  font-size: inherit;
}

.top-bar-title {
  margin: 0;
}
.top-bar-title img {
  min-width: 12.7777777778rem;
}

header {
  font-size: 0.7777777778rem;
}

.top-bar input {
  width: 200px;
}

.hamburger {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 12px;
  cursor: pointer;
}
.hamburger::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: #0a0a0a;
  box-shadow: 0 5px 0 #0a0a0a, 0 10px 0 #0a0a0a;
  content: "";
}
.hamburger:hover::after {
  background: #12326e;
  box-shadow: 0 5px 0 #12326e, 0 10px 0 #12326e;
}

footer {
  padding-top: 2.2222222222rem;
  font-size: 0.7777777778rem;
}
footer h1, footer h2, footer h3, footer h4, footer h5, footer h6 {
  color: inherit;
}

.footer-block {
  margin-bottom: 1.12rem;
}

.footer-social-links a {
  font-size: 1.6666666667rem;
  line-height: 1;
  padding: 0.3888888889rem;
  display: inline-block;
  border: solid 1px #fefefe;
}
.footer-social-links a:hover {
  background-color: #0b1e42;
}

.sign-off {
  background-image: url(../images/pigment-cloud.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
.sign-off .menu .menu-text {
  padding: 0;
  line-height: 24px;
  font-weight: 300;
}
.sign-off .menu a {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-color: rgba(254, 254, 254, 0.5);
}
.sign-off .menu a:hover {
  color: #fefefe;
  text-decoration-color: #fefefe;
  text-decoration-line: underline;
}
.sign-off__inner {
  max-width: 66.6666666667rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.5555555556rem;
  padding-left: 0.5555555556rem;
  float: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sign-off__inner::before, .sign-off__inner::after {
  display: table;
  content: " ";
  flex-basis: 0;
  order: 1;
}
.sign-off__inner::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .sign-off__inner {
    padding-right: 0.8333333333rem;
    padding-left: 0.8333333333rem;
  }
}
.sign-off__inner:last-child:not(:first-child) {
  float: right;
}

.hero {
  text-align: center;
}
.hero__header {
  font-weight: 400;
  font-size: 2rem;
}
.with-dark-background .hero {
  color: #fefefe;
}
.hero--homestyle {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: left;
  background-size: cover;
  min-height: 150px;
}
@media print, screen and (min-width: 40em) {
  .hero--homestyle {
    min-height: 275px;
  }
}
.hero--homestyle .hero__text {
  font-weight: 300;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fefefe;
  text-shadow: -1px -1px 0 #0a0a0a, 1px -1px 0 #0a0a0a, -1px 1px 0 #0a0a0a, 1px 1px 0 #0a0a0a;
}
.hero--homestyle .hero__text h1, .hero--homestyle .hero__text h2, .hero--homestyle .hero__text h3, .hero--homestyle .hero__text h4, .hero--homestyle .hero__text h5, .hero--homestyle .hero__text h6 {
  font-weight: inherit;
}

.section {
  padding-top: 2.2222222222rem;
  padding-bottom: 2.2222222222rem;
}
.section > :last-child {
  margin-bottom: 0;
}
.section + .section.section--following {
  padding-top: 0;
}
.section.with-dark-background {
  color: #fefefe;
}
.section.with-background-image {
  background-size: cover;
  background-position: center center;
}
.section.with-dark-overlay {
  background-image: linear-gradient(rgba(10, 10, 10, 0.7), rgba(10, 10, 10, 0.7)), url(https://www.xeikon.com/sites/default/files/styles/full_width/public/block-background/Xeikon-127.jpg);
}

.off-canvas .close-button {
  z-index: 10;
  color: #fefefe;
}
.off-canvas .drilldown a {
  background-color: #12326e;
  color: #fefefe;
}
.off-canvas .drilldown a:hover, .off-canvas .drilldown a:focus {
  text-decoration: underline;
}
.off-canvas .drilldown .is-drilldown-submenu {
  background-color: #12326e;
}
.off-canvas .drilldown .is-drilldown-submenu-parent > a::after {
  border-left-color: #fefefe;
}
.off-canvas .drilldown .js-drilldown-back > a::before {
  border-right-color: #fefefe;
}

html {
  scroll-behavior: smooth;
}

.card--customer, .card--keyline {
  border: solid 1px #12326e;
}
.card--customer .card-section, .card--keyline .card-section {
  flex: 0 1 auto;
}
.card--tint, .card--product {
  display: flex;
  flex-direction: column;
}
.card--tint .card-section, .card--product .card-section {
  display: flex;
  flex-direction: column;
}
.card--tint .card-section__top, .card--product .card-section__top {
  flex: 0 1 auto;
}
.card--tint .card-section__bottom, .card--product .card-section__bottom {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}
.card--tint .card-section__bottom a.button, .card--product .card-section__bottom a.button {
  margin-bottom: 0;
}
.card--tint {
  border: none;
  background-color: #f2f2f2;
}
.card--product.gg {
  background-color: #e7ebf1;
  border: none;
  border-top: 1.3333333333rem solid #12326e;
}
.card--product.gg a.button {
  background-color: #12326e;
  color: #fefefe;
}
.card--product.gg a.button:hover, .card--product.gg a.button:focus {
  background-color: #0f2b5e;
  color: #fefefe;
}
.card--product.gg2 {
  background-color: #fef2e9;
  border: none;
  border-top: 1.3333333333rem solid #f07e26;
}
.card--product.gg2 a.button {
  background-color: #f07e26;
  color: #fefefe;
}
.card--product.gg2 a.button:hover, .card--product.gg2 a.button:focus {
  background-color: #dd690f;
  color: #fefefe;
}
.card--product.tint {
  background-color: #fefefe;
  border: none;
  border-top: 1.3333333333rem solid #f2f2f2;
}
.card--product.tint a.button {
  background-color: #f2f2f2;
  color: #fefefe;
}
.card--product.tint a.button:hover, .card--product.tint a.button:focus {
  background-color: #cecece;
  color: #fefefe;
}
.card--dfe {
  height: 100%;
}
.card--dfe .card-section {
  padding-bottom: 0;
}
.card--dfe .card-section:last-child {
  padding-bottom: 1rem;
}
.card--dfe .card-section:first-child, .card--dfe .card-section:last-child {
  flex-grow: 0;
}
.card--dfe .card-section__bottom .button {
  margin-bottom: 0;
}
.media-object.media-object--executive-team, .media-object.media-object--keyline {
  padding: 1rem;
  border: solid 1px #12326e;
}
.media-object.media-object--tint {
  padding: 1rem;
  border: none;
  background-color: #f2f2f2;
}

.tabs--gg {
  border: none;
  background: none;
  margin: 0 0.5rem;
  text-align: center;
}
.tabs--gg .tabs-title {
  float: none !important;
  display: inline-block;
}
.tabs--gg .tabs-title > a {
  padding: 0.75rem 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #fefefe;
  text-decoration: none;
  background-color: #12326e;
}
.tabs--gg .tabs-title > a:focus, .tabs--gg .tabs-title > a[aria-selected=true], .tabs--gg .tabs-title > a[aria-selected=true]:hover {
  background: #f2f2f2;
  color: #12326e;
}
.tabs--gg .tabs-title > a:hover {
  color: #fefefe;
  background: #0f2b5e;
}
.tabs--gg .tabs-title:not(.column):not(.columns) > a {
  margin: 0 0.5rem 0.5rem;
}

.tabs-content--gg {
  border: none;
}
.tabs-content--gg .tabs-panel {
  padding: 0;
}

.news-abstract {
  margin-bottom: 1rem;
}

.time-and-place {
  margin-bottom: 0;
}

th {
  padding: 0.4444444444rem 0.5555555556rem 0.5555555556rem;
  font-weight: bold;
  text-align: left;
}

.background-teal, .strapline, footer {
  background-color: #12326e;
  color: #fefefe;
}
.background-teal a, .strapline a, footer a {
  color: #fefefe;
  text-decoration-color: rgba(254, 254, 254, 0.5);
}
.background-teal a:hover, .strapline a:hover, footer a:hover {
  color: #fefefe;
  text-decoration-color: #fefefe;
}

.color-gg {
  color: #12326e;
  color: #12326e;
}
.color-gg a {
  color: #12326e;
  text-decoration-color: rgba(18, 50, 110, 0.3);
}
.color-gg a:hover {
  color: rgba(18, 50, 110, 0.3);
  text-decoration-color: rgba(15, 43, 95, 0.3);
}
.color-gg blockquote {
  border-left: 1px solid #12326e;
}
.color-gg blockquote, .color-gg blockquote p {
  color: #12326e;
}
.color-gg cite {
  color: #12326e;
}
.color-gg.subheader {
  color: rgba(18, 50, 110, 0.3);
}

.background-gg {
  background-color: #12326e;
  color: #fefefe;
  color: #fefefe;
}
.background-gg a {
  color: #fefefe;
  text-decoration-color: rgba(254, 254, 254, 0.3);
}
.background-gg a:hover {
  color: rgba(254, 254, 254, 0.3);
  text-decoration-color: rgba(218, 218, 218, 0.3);
}
.background-gg blockquote {
  border-left: 1px solid #fefefe;
}
.background-gg blockquote, .background-gg blockquote p {
  color: #fefefe;
}
.background-gg cite {
  color: #fefefe;
}

.bg-gg {
  background-color: #12326e;
}

.color-gg2 {
  color: #f07e26;
  color: #f07e26;
}
.color-gg2 a {
  color: #f07e26;
  text-decoration-color: rgba(240, 126, 38, 0.3);
}
.color-gg2 a:hover {
  color: rgba(240, 126, 38, 0.3);
  text-decoration-color: rgba(224, 106, 15, 0.3);
}
.color-gg2 blockquote {
  border-left: 1px solid #f07e26;
}
.color-gg2 blockquote, .color-gg2 blockquote p {
  color: #f07e26;
}
.color-gg2 cite {
  color: #f07e26;
}
.color-gg2.subheader {
  color: rgba(240, 126, 38, 0.3);
}

.background-gg2 {
  background-color: #f07e26;
  color: #fefefe;
  color: #fefefe;
}
.background-gg2 a {
  color: #fefefe;
  text-decoration-color: rgba(254, 254, 254, 0.3);
}
.background-gg2 a:hover {
  color: rgba(254, 254, 254, 0.3);
  text-decoration-color: rgba(218, 218, 218, 0.3);
}
.background-gg2 blockquote {
  border-left: 1px solid #fefefe;
}
.background-gg2 blockquote, .background-gg2 blockquote p {
  color: #fefefe;
}
.background-gg2 cite {
  color: #fefefe;
}

.bg-gg2 {
  background-color: #f07e26;
}

.color-tint {
  color: #f2f2f2;
  color: #f2f2f2;
}
.color-tint a {
  color: #f2f2f2;
  text-decoration-color: rgba(242, 242, 242, 0.3);
}
.color-tint a:hover {
  color: rgba(242, 242, 242, 0.3);
  text-decoration-color: rgba(208, 208, 208, 0.3);
}
.color-tint blockquote {
  border-left: 1px solid #f2f2f2;
}
.color-tint blockquote, .color-tint blockquote p {
  color: #f2f2f2;
}
.color-tint cite {
  color: #f2f2f2;
}
.color-tint.subheader {
  color: rgba(242, 242, 242, 0.3);
}

.background-tint {
  background-color: #f2f2f2;
  color: #0a0a0a;
  color: #0a0a0a;
}
.background-tint a {
  color: #0a0a0a;
  text-decoration-color: rgba(10, 10, 10, 0.3);
}
.background-tint a:hover {
  color: rgba(10, 10, 10, 0.3);
  text-decoration-color: rgba(9, 9, 9, 0.3);
}
.background-tint blockquote {
  border-left: 1px solid #0a0a0a;
}
.background-tint blockquote, .background-tint blockquote p {
  color: #0a0a0a;
}
.background-tint cite {
  color: #0a0a0a;
}

.bg-tint {
  background-color: #f2f2f2;
}

.color-light-gray {
  color: #e6e6e6;
  color: #e6e6e6;
}
.color-light-gray a {
  color: #e6e6e6;
  text-decoration-color: rgba(230, 230, 230, 0.3);
}
.color-light-gray a:hover {
  color: rgba(230, 230, 230, 0.3);
  text-decoration-color: rgba(198, 198, 198, 0.3);
}
.color-light-gray blockquote {
  border-left: 1px solid #e6e6e6;
}
.color-light-gray blockquote, .color-light-gray blockquote p {
  color: #e6e6e6;
}
.color-light-gray cite {
  color: #e6e6e6;
}
.color-light-gray.subheader {
  color: rgba(230, 230, 230, 0.3);
}

.background-light-gray {
  background-color: #e6e6e6;
  color: #0a0a0a;
  color: #0a0a0a;
}
.background-light-gray a {
  color: #0a0a0a;
  text-decoration-color: rgba(10, 10, 10, 0.3);
}
.background-light-gray a:hover {
  color: rgba(10, 10, 10, 0.3);
  text-decoration-color: rgba(9, 9, 9, 0.3);
}
.background-light-gray blockquote {
  border-left: 1px solid #0a0a0a;
}
.background-light-gray blockquote, .background-light-gray blockquote p {
  color: #0a0a0a;
}
.background-light-gray cite {
  color: #0a0a0a;
}

.bg-light-gray {
  background-color: #e6e6e6;
}

.color-medium-gray {
  color: #cacaca;
  color: #cacaca;
}
.color-medium-gray a {
  color: #cacaca;
  text-decoration-color: rgba(202, 202, 202, 0.3);
}
.color-medium-gray a:hover {
  color: rgba(202, 202, 202, 0.3);
  text-decoration-color: rgba(174, 174, 174, 0.3);
}
.color-medium-gray blockquote {
  border-left: 1px solid #cacaca;
}
.color-medium-gray blockquote, .color-medium-gray blockquote p {
  color: #cacaca;
}
.color-medium-gray cite {
  color: #cacaca;
}
.color-medium-gray.subheader {
  color: rgba(202, 202, 202, 0.3);
}

.background-medium-gray {
  background-color: #cacaca;
  color: #0a0a0a;
  color: #0a0a0a;
}
.background-medium-gray a {
  color: #0a0a0a;
  text-decoration-color: rgba(10, 10, 10, 0.3);
}
.background-medium-gray a:hover {
  color: rgba(10, 10, 10, 0.3);
  text-decoration-color: rgba(9, 9, 9, 0.3);
}
.background-medium-gray blockquote {
  border-left: 1px solid #0a0a0a;
}
.background-medium-gray blockquote, .background-medium-gray blockquote p {
  color: #0a0a0a;
}
.background-medium-gray cite {
  color: #0a0a0a;
}

.bg-medium-gray {
  background-color: #cacaca;
}

.color-dark-gray {
  color: #8a8a8a;
  color: #8a8a8a;
}
.color-dark-gray a {
  color: #8a8a8a;
  text-decoration-color: rgba(138, 138, 138, 0.3);
}
.color-dark-gray a:hover {
  color: rgba(138, 138, 138, 0.3);
  text-decoration-color: rgba(119, 119, 119, 0.3);
}
.color-dark-gray blockquote {
  border-left: 1px solid #8a8a8a;
}
.color-dark-gray blockquote, .color-dark-gray blockquote p {
  color: #8a8a8a;
}
.color-dark-gray cite {
  color: #8a8a8a;
}
.color-dark-gray.subheader {
  color: rgba(138, 138, 138, 0.3);
}

.background-dark-gray {
  background-color: #8a8a8a;
  color: #fefefe;
  color: #fefefe;
}
.background-dark-gray a {
  color: #fefefe;
  text-decoration-color: rgba(254, 254, 254, 0.3);
}
.background-dark-gray a:hover {
  color: rgba(254, 254, 254, 0.3);
  text-decoration-color: rgba(218, 218, 218, 0.3);
}
.background-dark-gray blockquote {
  border-left: 1px solid #fefefe;
}
.background-dark-gray blockquote, .background-dark-gray blockquote p {
  color: #fefefe;
}
.background-dark-gray cite {
  color: #fefefe;
}

.bg-dark-gray {
  background-color: #8a8a8a;
}

.color-black {
  color: #0a0a0a;
  color: #0a0a0a;
}
.color-black a {
  color: #0a0a0a;
  text-decoration-color: rgba(10, 10, 10, 0.3);
}
.color-black a:hover {
  color: rgba(10, 10, 10, 0.3);
  text-decoration-color: rgba(9, 9, 9, 0.3);
}
.color-black blockquote {
  border-left: 1px solid #0a0a0a;
}
.color-black blockquote, .color-black blockquote p {
  color: #0a0a0a;
}
.color-black cite {
  color: #0a0a0a;
}
.color-black.subheader {
  color: rgba(10, 10, 10, 0.3);
}

.background-black {
  background-color: #0a0a0a;
  color: #fefefe;
  color: #fefefe;
}
.background-black a {
  color: #fefefe;
  text-decoration-color: rgba(254, 254, 254, 0.3);
}
.background-black a:hover {
  color: rgba(254, 254, 254, 0.3);
  text-decoration-color: rgba(218, 218, 218, 0.3);
}
.background-black blockquote {
  border-left: 1px solid #fefefe;
}
.background-black blockquote, .background-black blockquote p {
  color: #fefefe;
}
.background-black cite {
  color: #fefefe;
}

.bg-black {
  background-color: #0a0a0a;
}

.color-white {
  color: #fefefe;
  color: #fefefe;
}
.color-white a {
  color: #fefefe;
  text-decoration-color: rgba(254, 254, 254, 0.3);
}
.color-white a:hover {
  color: rgba(254, 254, 254, 0.3);
  text-decoration-color: rgba(218, 218, 218, 0.3);
}
.color-white blockquote {
  border-left: 1px solid #fefefe;
}
.color-white blockquote, .color-white blockquote p {
  color: #fefefe;
}
.color-white cite {
  color: #fefefe;
}
.color-white.subheader {
  color: rgba(254, 254, 254, 0.3);
}

.background-white {
  background-color: #fefefe;
  color: #0a0a0a;
  color: #0a0a0a;
}
.background-white a {
  color: #0a0a0a;
  text-decoration-color: rgba(10, 10, 10, 0.3);
}
.background-white a:hover {
  color: rgba(10, 10, 10, 0.3);
  text-decoration-color: rgba(9, 9, 9, 0.3);
}
.background-white blockquote {
  border-left: 1px solid #0a0a0a;
}
.background-white blockquote, .background-white blockquote p {
  color: #0a0a0a;
}
.background-white cite {
  color: #0a0a0a;
}

.bg-white {
  background-color: #fefefe;
}

a.button {
  text-decoration: none;
}

.hero__placeholder {
  background-color: #8a8a8a;
  height: 16.6666666667rem;
}

.cta {
  margin-top: 2.2222222222rem;
}

@media print, screen and (min-width: 40em) {
  .columns-medium-2 {
    columns: 2 10rem;
    column-gap: 30px;
  }
}
.badge {
  border-radius: 0;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  text-decoration-line: none;
  background-size: 0.05em 0.05em;
  background-repeat: repeat-x;
  background-position: 0% 91%;
  background-image: linear-gradient(rgba(18, 50, 110, 0.3), rgba(18, 50, 110, 0.3));
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  text-decoration-line: none;
  background-image: linear-gradient(#0f2b5f, #0f2b5f);
}

a {
  text-decoration-color: rgba(18, 50, 110, 0.3);
}
a:hover {
  text-decoration-color: #0f2b5f;
}

a.text-decoration {
  text-decoration-line: underline !important;
  background-image: none !important;
}

.color-gg a {
  text-decoration-color: rgba(18, 50, 110, 0.3);
}
.color-gg a:hover {
  color: #0f2b5f;
  text-decoration-color: #0f2b5f;
}
.color-gg h1 a, .color-gg h2 a, .color-gg h3 a, .color-gg h4 a, .color-gg h5 a, .color-gg h6 a {
  background-image: linear-gradient(rgba(18, 50, 110, 0.3), rgba(18, 50, 110, 0.3));
}
.color-gg h1 a:hover, .color-gg h2 a:hover, .color-gg h3 a:hover, .color-gg h4 a:hover, .color-gg h5 a:hover, .color-gg h6 a:hover {
  background-image: linear-gradient(#0f2b5f, #0f2b5f);
}

.background-gg h1 a, .background-gg h2 a, .background-gg h3 a, .background-gg h4 a, .background-gg h5 a, .background-gg h6 a {
  background-image: linear-gradient(rgba(254, 254, 254, 0.3), rgba(254, 254, 254, 0.3));
}
.background-gg h1 a:hover, .background-gg h2 a:hover, .background-gg h3 a:hover, .background-gg h4 a:hover, .background-gg h5 a:hover, .background-gg h6 a:hover {
  color: #dadada;
  background-image: linear-gradient(#dadada, #dadada);
}

.color-gg2 a {
  text-decoration-color: rgba(240, 126, 38, 0.3);
}
.color-gg2 a:hover {
  color: #e06a0f;
  text-decoration-color: #e06a0f;
}
.color-gg2 h1 a, .color-gg2 h2 a, .color-gg2 h3 a, .color-gg2 h4 a, .color-gg2 h5 a, .color-gg2 h6 a {
  background-image: linear-gradient(rgba(240, 126, 38, 0.3), rgba(240, 126, 38, 0.3));
}
.color-gg2 h1 a:hover, .color-gg2 h2 a:hover, .color-gg2 h3 a:hover, .color-gg2 h4 a:hover, .color-gg2 h5 a:hover, .color-gg2 h6 a:hover {
  background-image: linear-gradient(#e06a0f, #e06a0f);
}

.background-gg2 h1 a, .background-gg2 h2 a, .background-gg2 h3 a, .background-gg2 h4 a, .background-gg2 h5 a, .background-gg2 h6 a {
  background-image: linear-gradient(rgba(254, 254, 254, 0.3), rgba(254, 254, 254, 0.3));
}
.background-gg2 h1 a:hover, .background-gg2 h2 a:hover, .background-gg2 h3 a:hover, .background-gg2 h4 a:hover, .background-gg2 h5 a:hover, .background-gg2 h6 a:hover {
  color: #dadada;
  background-image: linear-gradient(#dadada, #dadada);
}

.color-tint a {
  text-decoration-color: rgba(242, 242, 242, 0.3);
}
.color-tint a:hover {
  color: #d0d0d0;
  text-decoration-color: #d0d0d0;
}
.color-tint h1 a, .color-tint h2 a, .color-tint h3 a, .color-tint h4 a, .color-tint h5 a, .color-tint h6 a {
  background-image: linear-gradient(rgba(242, 242, 242, 0.3), rgba(242, 242, 242, 0.3));
}
.color-tint h1 a:hover, .color-tint h2 a:hover, .color-tint h3 a:hover, .color-tint h4 a:hover, .color-tint h5 a:hover, .color-tint h6 a:hover {
  background-image: linear-gradient(#d0d0d0, #d0d0d0);
}

.background-tint h1 a, .background-tint h2 a, .background-tint h3 a, .background-tint h4 a, .background-tint h5 a, .background-tint h6 a {
  background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(10, 10, 10, 0.3));
}
.background-tint h1 a:hover, .background-tint h2 a:hover, .background-tint h3 a:hover, .background-tint h4 a:hover, .background-tint h5 a:hover, .background-tint h6 a:hover {
  color: #090909;
  background-image: linear-gradient(#090909, #090909);
}

.color-light-gray a {
  text-decoration-color: rgba(230, 230, 230, 0.3);
}
.color-light-gray a:hover {
  color: #c6c6c6;
  text-decoration-color: #c6c6c6;
}
.color-light-gray h1 a, .color-light-gray h2 a, .color-light-gray h3 a, .color-light-gray h4 a, .color-light-gray h5 a, .color-light-gray h6 a {
  background-image: linear-gradient(rgba(230, 230, 230, 0.3), rgba(230, 230, 230, 0.3));
}
.color-light-gray h1 a:hover, .color-light-gray h2 a:hover, .color-light-gray h3 a:hover, .color-light-gray h4 a:hover, .color-light-gray h5 a:hover, .color-light-gray h6 a:hover {
  background-image: linear-gradient(#c6c6c6, #c6c6c6);
}

.background-light-gray h1 a, .background-light-gray h2 a, .background-light-gray h3 a, .background-light-gray h4 a, .background-light-gray h5 a, .background-light-gray h6 a {
  background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(10, 10, 10, 0.3));
}
.background-light-gray h1 a:hover, .background-light-gray h2 a:hover, .background-light-gray h3 a:hover, .background-light-gray h4 a:hover, .background-light-gray h5 a:hover, .background-light-gray h6 a:hover {
  color: #090909;
  background-image: linear-gradient(#090909, #090909);
}

.color-medium-gray a {
  text-decoration-color: rgba(202, 202, 202, 0.3);
}
.color-medium-gray a:hover {
  color: #aeaeae;
  text-decoration-color: #aeaeae;
}
.color-medium-gray h1 a, .color-medium-gray h2 a, .color-medium-gray h3 a, .color-medium-gray h4 a, .color-medium-gray h5 a, .color-medium-gray h6 a {
  background-image: linear-gradient(rgba(202, 202, 202, 0.3), rgba(202, 202, 202, 0.3));
}
.color-medium-gray h1 a:hover, .color-medium-gray h2 a:hover, .color-medium-gray h3 a:hover, .color-medium-gray h4 a:hover, .color-medium-gray h5 a:hover, .color-medium-gray h6 a:hover {
  background-image: linear-gradient(#aeaeae, #aeaeae);
}

.background-medium-gray h1 a, .background-medium-gray h2 a, .background-medium-gray h3 a, .background-medium-gray h4 a, .background-medium-gray h5 a, .background-medium-gray h6 a {
  background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(10, 10, 10, 0.3));
}
.background-medium-gray h1 a:hover, .background-medium-gray h2 a:hover, .background-medium-gray h3 a:hover, .background-medium-gray h4 a:hover, .background-medium-gray h5 a:hover, .background-medium-gray h6 a:hover {
  color: #090909;
  background-image: linear-gradient(#090909, #090909);
}

.color-dark-gray a {
  text-decoration-color: rgba(138, 138, 138, 0.3);
}
.color-dark-gray a:hover {
  color: #777777;
  text-decoration-color: #777777;
}
.color-dark-gray h1 a, .color-dark-gray h2 a, .color-dark-gray h3 a, .color-dark-gray h4 a, .color-dark-gray h5 a, .color-dark-gray h6 a {
  background-image: linear-gradient(rgba(138, 138, 138, 0.3), rgba(138, 138, 138, 0.3));
}
.color-dark-gray h1 a:hover, .color-dark-gray h2 a:hover, .color-dark-gray h3 a:hover, .color-dark-gray h4 a:hover, .color-dark-gray h5 a:hover, .color-dark-gray h6 a:hover {
  background-image: linear-gradient(#777777, #777777);
}

.background-dark-gray h1 a, .background-dark-gray h2 a, .background-dark-gray h3 a, .background-dark-gray h4 a, .background-dark-gray h5 a, .background-dark-gray h6 a {
  background-image: linear-gradient(rgba(254, 254, 254, 0.3), rgba(254, 254, 254, 0.3));
}
.background-dark-gray h1 a:hover, .background-dark-gray h2 a:hover, .background-dark-gray h3 a:hover, .background-dark-gray h4 a:hover, .background-dark-gray h5 a:hover, .background-dark-gray h6 a:hover {
  color: #dadada;
  background-image: linear-gradient(#dadada, #dadada);
}

.color-black a {
  text-decoration-color: rgba(10, 10, 10, 0.3);
}
.color-black a:hover {
  color: #090909;
  text-decoration-color: #090909;
}
.color-black h1 a, .color-black h2 a, .color-black h3 a, .color-black h4 a, .color-black h5 a, .color-black h6 a {
  background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(10, 10, 10, 0.3));
}
.color-black h1 a:hover, .color-black h2 a:hover, .color-black h3 a:hover, .color-black h4 a:hover, .color-black h5 a:hover, .color-black h6 a:hover {
  background-image: linear-gradient(#090909, #090909);
}

.background-black h1 a, .background-black h2 a, .background-black h3 a, .background-black h4 a, .background-black h5 a, .background-black h6 a {
  background-image: linear-gradient(rgba(254, 254, 254, 0.3), rgba(254, 254, 254, 0.3));
}
.background-black h1 a:hover, .background-black h2 a:hover, .background-black h3 a:hover, .background-black h4 a:hover, .background-black h5 a:hover, .background-black h6 a:hover {
  color: #dadada;
  background-image: linear-gradient(#dadada, #dadada);
}

.color-white a {
  text-decoration-color: rgba(254, 254, 254, 0.3);
}
.color-white a:hover {
  color: #dadada;
  text-decoration-color: #dadada;
}
.color-white h1 a, .color-white h2 a, .color-white h3 a, .color-white h4 a, .color-white h5 a, .color-white h6 a {
  background-image: linear-gradient(rgba(254, 254, 254, 0.3), rgba(254, 254, 254, 0.3));
}
.color-white h1 a:hover, .color-white h2 a:hover, .color-white h3 a:hover, .color-white h4 a:hover, .color-white h5 a:hover, .color-white h6 a:hover {
  background-image: linear-gradient(#dadada, #dadada);
}

.background-white h1 a, .background-white h2 a, .background-white h3 a, .background-white h4 a, .background-white h5 a, .background-white h6 a {
  background-image: linear-gradient(rgba(10, 10, 10, 0.3), rgba(10, 10, 10, 0.3));
}
.background-white h1 a:hover, .background-white h2 a:hover, .background-white h3 a:hover, .background-white h4 a:hover, .background-white h5 a:hover, .background-white h6 a:hover {
  color: #090909;
  background-image: linear-gradient(#090909, #090909);
}

.customer-logos {
  align-items: center;
}
.customer-logos img {
  padding: 10px;
}
.customer-logos:first-child {
  padding-left: 0;
}
.customer-logos:last-child {
  padding-right: 0;
}

blockquote p {
  color: inherit;
}
blockquote.hero {
  border-left: none;
  padding: 0;
  margin-bottom: 0;
}
blockquote.hero cite {
  font-size: 0.7777777778rem;
  margin-bottom: 0.5555555556rem;
}
blockquote.hero .hero__logo {
  max-width: 5rem;
  margin: auto;
  margin-bottom: 0.5555555556rem;
}
blockquote.hero cite:before {
  content: none;
}

.with-dark-background cite {
  color: #fefefe;
}

.pagination .active {
  padding: 0.1666666667rem 0.5555555556rem;
  background: #12326e;
  color: #fefefe;
  cursor: default;
}
.pagination .prev a::before,
.pagination .prev.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: "«";
}
.pagination .next a::after,
.pagination .next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: "»";
}

.read-our-blog {
  background-color: #0a5d99;
}
.read-our-blog__head {
  color: #fefefe;
  background-image: url("../images/pigment-cloud.jpg");
  background-size: cover;
  background-position: left center;
}
.read-our-blog__head h1 {
  margin-bottom: 0;
}
.read-our-blog__body {
  background-color: #fefefe;
}

#ad-partner-network-1 {
  position: relative;
  color: white;
  background-color: #404041;
  overflow: hidden;
  z-index: 0;
}
#ad-partner-network-1::before, #ad-partner-network-1::after {
  content: "";
  position: absolute;
  z-index: -1;
  width: 300px;
  height: 300px;
  background: url("../images/banners/partner-network-circles-01.svg") no-repeat 0 0;
}
#ad-partner-network-1::before {
  left: -150px;
  top: 50%;
  transform: translate(-30%, -50%);
  background-position-x: 0;
  background-position-y: 0;
}
#ad-partner-network-1::after {
  right: -80px;
  top: 0;
  transform: translate(0, -80%) rotate(90deg);
  background-position-x: 100%;
  background-position-y: 0;
}
@media print, screen and (min-width: 40em) {
  #ad-partner-network-1::before {
    transform: translate(0, -50%);
  }
  #ad-partner-network-1::after {
    transform: translate(0, -60%) rotate(90deg);
  }
  #ad-partner-network-1 .line1, #ad-partner-network-1 .line2, #ad-partner-network-1 .line3, #ad-partner-network-1 .line4 {
    padding-left: 15%;
    padding-right: 15%;
  }
}
#ad-partner-network-1 .line1 img {
  height: 49px;
}
#ad-partner-network-1 .line2, #ad-partner-network-1 .line3 {
  font-size: 24px;
  line-height: 25px;
}
#ad-partner-network-1 .line4 {
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 5px;
}
#ad-partner-network-1 .line4 a {
  color: inherit;
  font-weight: bold;
  text-decoration-color: inherit;
  text-decoration-line: none;
}
#ad-partner-network-1 .line4 a:hover {
  text-decoration-line: underline;
}

.ad-partner-network-2 {
  position: relative;
  color: white;
  background-color: #12326e;
  font-size: 22px;
  line-height: 1.2;
  font-weight: bold;
}
.ad-partner-network-2 p {
  line-height: inherit;
}
.ad-partner-network-2 p:last-child {
  margin-bottom: 0;
}
.ad-partner-network-2 a {
  display: inline-block;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.1;
}
.ad-partner-network-2 .ad-partner-network-2__image, .ad-partner-network-2 .ad-partner-network-2__text {
  padding-top: 10px;
  padding-bottom: 10px;
}
@media print, screen and (min-width: 40em) {
  .ad-partner-network-2 .ad-partner-network-2__image, .ad-partner-network-2 .ad-partner-network-2__text {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.ad-partner-network-2 .ad-partner-network-2__image {
  background-color: white;
}
@media print, screen and (min-width: 40em) {
  .ad-partner-network-2 .ad-partner-network-2__image {
    flex: 0 1 261px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.ad-partner-network-2 .ad-partner-network-2__text img {
  height: 6rem;
}
@media print, screen and (min-width: 40em) {
  .ad-partner-network-2 .ad-partner-network-2__text {
    font-size: 24px;
    line-height: 1.2;
    font-weight: bold;
  }
}

.at-a-glance .columns {
  text-align: center;
}
.at-a-glance .columns > p:first-child {
  margin-bottom: 0;
}
.at-a-glance .columns > p:first-child img {
  margin-bottom: 1.6666666667rem;
  height: 8rem;
}

.columns > .map-address {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}
.columns > .map-address .map, .columns > .map-address .address {
  margin-bottom: 1rem;
}