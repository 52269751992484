.at-a-glance {
  .columns {
    text-align: center;
  }
  .columns > h5:first-child { // heading at top of column
    //margin-bottom: $standard-vertical-space;
  }

  .columns > p:first-child {
    margin-bottom: 0; // put the margin-bottom on the image only
    img { // image at top of column
      margin-bottom: $standard-vertical-space - $paragraph-margin-bottom;;
      height: 8rem;
    }
  }
}