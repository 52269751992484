.section {
  //margin-bottom: $standard-vertical-space;
  padding-top: $standard-vertical-space;
  padding-bottom: $standard-vertical-space;
  & > :last-child {
    margin-bottom: 0; // cancel the bottom margin on the last child
  }

  .section + &.section--following { // one section followed by another section
    padding-top: 0;
  }

  //background-color: rgba($light-gray, 0.5); // temporary background color to help understand spacing

  // generic sections stuff to live somewhere more generic/top-level
  // section utility classes
  &.with-dark-background {
    color: $white;
  }

  &.with-background-image {
    background-size: cover;
    background-position: center center;
  }

  &.with-dark-overlay {
    //background-color:rgba($black ,0.7); // it's not possible to stack background(-image) this way
    background-image: // but we can do it this way - the trouble is; how can a C5 block do this?
            linear-gradient(rgba(10, 10, 10, 0.7), rgba(10, 10, 10, 0.7)),
            url(https://www.xeikon.com/sites/default/files/styles/full_width/public/block-background/Xeikon-127.jpg);
  }

}