.off-canvas {
  .close-button {
    z-index: 10; // above the drill-down menu
    color: $white;
    &:hover, &:focus {
      //text-decoration: none;
    }
  }
  //@extend .background-teal;
  .drilldown { // putting a teal background on the drilldown is a little bit tricky unless this is done in _settings.scss for *every* drilldown
    a {
      background-color: $ggteal;
      color: $white;
      &:hover, &:focus {
        text-decoration: underline;
      }
    }
    .is-drilldown-submenu {
      background-color: $ggteal;
    }
    .is-drilldown-submenu-parent > a::after {
      border-left-color: $white;
    }
    .js-drilldown-back > a::before {
      border-right-color: $white;
    }
  }
}
html {
  scroll-behavior: smooth;
}