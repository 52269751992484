.customer-logos {
  align-items: center; // vertically centered
  img {
    padding: 10px;
  }
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

%quote-partial { // inspired by https://css-tricks.com/snippets/css/simple-and-nice-blockquote-styling/
  font-size: $hero-header-font-size * 2; // 4em;
  line-height: $hero-header-font-size * 0.1; // 0.1em;
  vertical-align: $hero-header-font-size * -0.4; // -0.4em;
}

blockquote {
  p {
    color: inherit;
  }

  &.hero {
    border-left: none; // a hero blockquote has no left border
    padding: 0; // a hero blockquote has no padding
    margin-bottom: 0;
    cite {
      font-size: rem-calc(14); // a hero blockquote has a slightly larger font size
      margin-bottom: $paragraph-margin-bottom;
    }
    & .hero__logo {
      max-width: rem-calc(90);
      margin: auto;
      margin-bottom: $paragraph-margin-bottom;
    }
    cite:before {
      content: none;
    }
  }

}

cite {
  .with-dark-background & {
    color: $white;
  }
}